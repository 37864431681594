import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './careers.css'
import Navbar from '../Navbar'
import { toast } from 'react-hot-toast';
import axios from 'axios';
import CareerContainer from './CareerContainer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 'auto',
  overflowX: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function Careers({ isAdmin }) {
  const [open, setOpen] = useState(false);
  const [formdata, setFormData] = useState({ name: "", description: "", requirement: "", qualifications: "", location: "", mode: "", responsibilities: "", endDate: "" })
  const [formerror, setFormError] = useState({ name: false, description: false, requirement: false, qualifications: false, location: false, mode: false, responsibilities: false, endDate: false })
  const [careers, setCareers] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleFormData = (e) => {
    const { name, value } = e.target
    const newformdata = { ...formdata };
    newformdata[name] = value
    setFormData(newformdata)
  }

  const handleAdd = async () => {
    let isError = false;
    const newError = { ...formerror };
    if (!formdata.name.length) {
      newError.name = true;
      isError = true
    }
    if (!formdata.description.length) {
      newError.description = true;
      isError = true
    }
    if (!formdata.location.length) {
      newError.location = true;
      isError = true
    }
    if (!formdata.mode.length) {
      newError.mode = true;
      isError = true
    }
    if (isError) {
      setFormError(newError);
      return;
    }
    await toast.promise(
      axios.post(`${baseUrl}/career/addnewjob`, {
        formdata
      }),
      {
        pending: "Adding new job...",
        success: "Job Added",
        error: "Unable to add job",
        loading: "Adding new job...",
      }
    );
    await handleclose();
    await getJobs()

  }

  const handleclose = async () => {
    setOpen(false);
    setFormData({ name: "", description: "", requirement: "", qualifications: "", location: "", mode: "", responsibilities: "", endDate: "" })
    setFormError({ name: false, description: false, requirement: false, qualifications: false, location: false, mode: false, responsibilities: false, endDate: false })
  }

  const getJobs = async () => {
    const jobs = await toast.promise(
      axios.get(`${baseUrl}/career/getalljobs`),
      {
        pending: "Fetching jobs...",
        success: "Job Fetched",
        error: "Unable to fetch job",
        loading: "Fetching job...",
      }
    );
    jobs.data.sort((a, b) => {
      const dataA = new Date(a.createdAt);
      const dataB = new Date(b.createdAt);
      return dataB - dataA
    })
    setCareers(jobs.data);
  }

  useEffect(() => {
    getJobs();
  }, [baseUrl])

  return (
    <div className='careers'>
      {!isAdmin && <Navbar />}
      {isAdmin && <div className='addbutton'>
        <Button variant="contained" onClick={() => setOpen(true)}>Add New Job</Button>
      </div>}
      <Modal
        open={open}
        onClose={() => { handleclose() }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={{ ...style }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Job
          </Typography>
          <Box component="form"
            noValidate
            autoComplete="off" sx={{ '& > :not(style)': { m: 1 }, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
            <TextField id="outlined-basic" label="Job Name" variant="outlined" required error={formerror.name} sx={{ width: "100%", margin: "10px" }} name="name" onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Description" variant="outlined" required error={formerror.description} sx={{ width: "100%", margin: "10px" }} name="description" multiline onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Requirement" variant="outlined" sx={{ width: "100%", margin: "10px" }} name="requirement" multiline onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Qualifications" variant="outlined" sx={{ width: "100%", margin: "10px" }} name="qualifications" multiline onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Location" variant="outlined" required error={formerror.location} sx={{ width: "100%", margin: "10px" }} name="location" onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Mode" variant="outlined" required error={formerror.mode} sx={{ width: "100%", margin: "10px" }} name="mode" onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job Responsibilities" variant="outlined" sx={{ width: "100%", margin: "10px" }} multiline name="responsibilities" onChange={(e) => handleFormData(e)} />
            <TextField id="outlined-basic" label="Job End Date" variant="outlined" sx={{ width: "100%", margin: "10px" }} type='date' name="endDate" onChange={(e) => handleFormData(e)} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant='outlined' color="error" sx={{ marginRight: "10px" }}>Cancel</Button>
            <Button variant='contained' onClick={handleAdd}>Add</Button>
          </Box>
        </Box>
      </Modal>
      <div>
        {careers.map((job,i) => {
          return <CareerContainer key={i} data={job} />
        })}
      </div>
    </div>
  )
}

export default Careers