import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import jwt_decode from "jwt-decode";
import fetchData from "../helper/apiCall";
import Navbar from '../components/Navbar'

function Login() {
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const inputChange = (e) => {
    const { name, value } = e.target;
    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };
  const baseUrl = process.env.REACT_APP_BASE_URL

  const formSubmit = async (e) => {
    let toastId;
    try {
      e.preventDefault();
      const { email, password } = formDetails;
      if (!email || !password) {
        return toast.error("Input field should not be empty");
      } else if (password.length < 5) {
        return toast.error("Password must be at least 5 characters long");
      }
      toastId = toast.loading('Logging in...');
      const response = await axios.post(baseUrl + "/user/login", {
        email,
        password,
      });
      const { data } = response;
      console.log('data',data)
      if (data && data.msg) {
        toast.success(data.msg);
        localStorage.setItem("token", data.token);
        dispatch(setUserInfo(jwt_decode(data.token).userId));
        await getUser(jwt_decode(data.token).userId);
      }else{
        toast.error("Something went wrong!!");
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        console.log('data', data);
        if (data) {
          toast.error(data);
        } else {
          toast.error("An error occurred"); // Default error message
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received from server");
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error:", error.message);
        toast.error("Request failed");
      }
    } finally {
      toast.dismiss(toastId);
    }
  };

  const getUser = async (id) => {
    try {
      const temp = await fetchData(`${baseUrl}/user/getuser/${id}`);
      dispatch(setUserInfo(temp));
      if (temp.isAdmin) {
        return navigate("/dashboard/users");
      }
      return navigate("/");
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Navbar />
      <section className="register-section flex-center login">
        <div className="register-container flex-center">
          <h2 className="form-heading">Log In</h2>
          <form
            onSubmit={formSubmit}
            className="register-form"
          >
            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={formDetails.email}
              onChange={inputChange}
            />
            <input
              type="password"
              name="password"
              className="form-input"
              placeholder="Enter your password"
              value={formDetails.password}
              onChange={inputChange}
            />
            <button
              type="submit"
              className="btn form-btn"
            >
              Login
            </button>
          </form>
          <p>
            Don't remember password?            <NavLink
              className="login-link"
              to={"/forget-password"}
            >
              Reset
            </NavLink>
          </p>
          <p>
            Not a user?{" "}
            <NavLink
              className="login-link"
              to={"/register"}
            >
              Register
            </NavLink>
          </p>
        </div>
      </section></>
  );
}

export default Login;
