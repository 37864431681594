import React, { useEffect, useState } from "react";
import Empty from "../components/Empty";
import Navbar from "../components/Navbar";
import fetchData from "../helper/apiCall";
import jwt_decode from "jwt-decode";
import '../styles/search.css';

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [filterDate, setfilterDate] = useState("");
  const { userId } = jwt_decode(localStorage.getItem("token"));
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getAllAppoint = async () => {
    try {
      const temp = await fetchData(
        `${baseUrl}/appointment/getallappointments?search=${userId}`
      );
      setAppointments(temp);
    } catch (error) { }
  };



  useEffect(() => {
    // dispatch(setLoading(true));
    getAllAppoint();
    // dispatch(setLoading(false));
  }, []);

  return (
    <>
      <Navbar />
      <section className="container">
        <h2 className="page-heading">Your Appointments</h2>
        <div className="search">
          <div >
            <p>Search By Date</p>
            <input
              type="date"
              placeholder="Search By Date"
              className="form-input"
              onChange={(e) => setfilterDate(e.target.value)}
              value={filterDate}
            />
          </div>
          <button
            className="btn user-btn"
            style={{ marginLeft: "10px", marginRight: "20px", marginBottom: "10px" }}
            onClick={() => setfilterDate("")}
          >
            Clear
          </button>
        </div>
        {appointments.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {/* <th>Doctor</th> */}
                    <th>Patient</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Booking Date</th>
                    <th>Booking Time</th>
                    <th>Status</th>
                    {/* {userId === appointments[0].doctorId._id ? (
                    <th>Action</th>
                  ) : (
                    <></>
                  )} */}
                  </tr>
                </thead>
                <tbody>
                  {appointments.map((ele, i) => {
                    if (filterDate === "" || filterDate === ele.appointmentdate) {
                      return (
                        <tr key={i} >
                          <td>{i + 1}</td>
                          {/* <td>
                        {ele.doctorId.firstname + " " + ele.doctorId.lastname}
                      </td> */}
                          <td>
                            {ele.patient}
                          </td>
                          <td>{ele.appointmentdate}</td>
                          <td>{ele.appointmenttime}</td>
                          <td>{ele.bookingdate}</td>
                          <td>{ele.updatedAt.split("T")[1].split(".")[0]}</td>
                          <td>{ele.status}</td>
                          {/* {userId === ele.doctorId._id ? (
                        <td>
                          <button
                            className={`btn user-btn accept-btn ${ele.status === "Completed" ? "disable-btn" : ""
                              }`}
                            disabled={ele.status === "Completed"}
                            onClick={() => complete(ele)}
                          >
                            Complete
                          </button>
                        </td>
                      ) : (
                        <></>
                      )} */}
                        </tr>
                      );
                    }
                    else {
                      return <></>
                    }
                  })}
                </tbody>
              </table></div>
          </div>
        ) : (
          <Empty />
        )}
      </section>
    </>
  );
};
export default Appointments;
