import React from "react";
import { Helmet } from "react-helmet";

const Tracking = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-HKVLJP92K0"
    ></script>
    <script>
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-HKVLJP92K0');
        `}
    </script>
    <meta
      name="google-site-verification"
      content="WbwwTm-KoPQPrADtIQDkJPh7DVvIpuusJfx4Lh4SfYk"
    />
    <meta name="msvalidate.01" content="C88DA9569F093ADEC4A853543985CFCF" />

    <script type="text/javascript">
      {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "j6e68wu6qh");
        `}
    </script>
  </Helmet>
);

export default Tracking;
