const img1 = [];
const img2 = [];
const img3 = [];
const img4 = [];

for (let i = 1; i <= 30; i++) {
  img1.push(require(`../../images/2019/${i}.JPG`));
}

for (let i = 1; i <= 25; i++) {
  img2.push(require(`../../images/2021/${i}.jpg`));
}

for (let i = 1; i <= 56; i++) {
  img3.push(require(`../../images/inaguruation/${i}.JPG`));
}

for (let i = 1; i <= 26; i++) {
  img4.push(require(`../../images/facilities/${i}.JPG`));
}

export const gallery = [
  {
    name: "World Physiotherapy Day 2019",
    labelImg: require("../../images/2019/1.JPG"),
    images: [...img1],
  },
  {
    name: "Free Physiotherapy Camp 2021",
    labelImg: require("../../images/2021/1.jpg"),
    images: [...img2],
  },
  {
    name: "Inauguration of Thera-Cure",
    labelImg: require("../../images/inaguruation/1.JPG"),
    images: [...img3],
  },
  {
    name: "Our Facilities",
    labelImg: require("../../images/facilities/1.JPG"),
    images: [...img4],
  },
];
