import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import AppointmentBookingDetail from './AppointmentBookingDetail';

const data = [
  {
    title: "Test",
    time: "9:00 AM - 10:00 AM",
    _id: 1,
  },
  {
    title: "Test",
    time: "10:00 AM - 11:00 AM",
    _id: 2,
  },
  {
    title: "Test",
    time: "11:00 AM - 12:00 AM",
    _id: 3,
  },
  {
    title: "Test",
    time: "6:00 PM - 7:00 PM",
    _id: 4,
  },
  {
    title: "Test",
    time: "7:00 PM - 8:00 PM",
    _id: 5,
  },
  {
    title: "Test",
    time: "8:00 PM - 9:00 PM",
    _id: 1,
  },
];


function BookAppointment() {
  const [open, setOpen] = useState(false);
  const [book, setBook] = useState("")
  const handleOpen = (booking) => {
    setOpen(true);
    setBook(booking);
  }
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Navbar />
      <div style={{ display: "flex", justifyContent: "space-around", alignContent: "center", flexWrap: "wrap" }}>
        {data.map((booking,i) => {
          return <div key={i} style={{ flex: 1 }}>
            <div className="card p-3" style={{ minWidth: "480px", margin: "30px" }}>
              <h5 className="card-title brand-color">{booking.title}</h5>
              <h6>{booking.time}</h6>
              <button onClick={() => handleOpen(booking)} className="btn btn-primary">
                Book AppointMent
              </button>
            </div>
          </div>
        })}
        <AppointmentBookingDetail open={open} booking={book} handleClose={handleClose} />

      </div>
    </div >
  )
}

export default BookAppointment