import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import Navbar from '../components/Navbar'

function Login() {
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isCorrectOtp, setIsCorrectOtp] = useState(false)
    const [isVerified, setIsVerified] = useState(false)

    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL

    const resetPassword = async () => {
        let toastId;
        try {
            if (!password || password !== confirmPassword) {
                return toast.error("Input field should not be empty");
            } else if (password.length < 5) {
                return toast.error("Password must be at least 5 characters long");
            }
            toastId = toast.loading('Reseting Password...');
            const res = await axios.post(baseUrl + "/user/resetPassword", {
                email,
                otp,
                password
            })
            if (res.data.success) {
                setIsVerified(true);
                toast.success("Password reset sent successfully");
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            return error;
        } finally {
            toast.dismiss(toastId);
        }
    }

    const getOtp = async (e) => {
        let toastId;
        try {
            if (!email) {
                return toast.error("Input field should not be empty");
            }
            toastId = toast.loading('Sending OTP...');
            const res = await axios.post(baseUrl + "/user/forgetPassword", {
                email,
            })
            if (res.data.success) {
                setIsCorrectOtp(true);
                toast.success("OTP sent successfully");
            } else {
                toast.error(res.data.message);
            }

        } catch (error) {
            return error;
        } finally {
            toast.dismiss(toastId);
        }
    };

    const handleClick = () => {
        if (isCorrectOtp) {
            resetPassword()
        } else {
            getOtp()
        }
    }

    return (
        <>
            <Navbar />
            <section className="register-section flex-center login">
                <div className="register-container flex-center">
                    {!isVerified ? <> <h2 className="form-heading">Reset Password</h2>
                        <input
                            type="email"
                            name="email"
                            className="form-input"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isCorrectOtp}
                        />
                        {isCorrectOtp && <><input
                            type="text"
                            name="otp"
                            className="form-input"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                            <input
                                type="password"
                                name="password"
                                className="form-input"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <input
                                type="password"
                                name="confirmpassword"
                                className="form-input"
                                placeholder="Enter your confirm password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </>}
                        <button
                            className="btn form-btn"
                            onClick={handleClick}
                        >
                            {isCorrectOtp ? "Reset" : "Get Otp"}
                        </button></> : <>
                        <h2 >Password Reset Successful !!</h2>
                        <button
                            className="btn form-btn"
                            onClick={() => { navigate("/login") }}
                        >
                            Login
                        </button>
                    </>}
                </div>
            </section></>
    );
}

export default Login;
