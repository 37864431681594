import React, { useState } from "react";
import "./Card.css"; // Assume you have a separate CSS file for styling
import { useNavigate } from "react-router-dom";

const Card = ({ imageUrl, name, link }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <a
      href={`/services/${link}`}
      className={`card ${hovered ? "hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="card-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="overlay"></div>
        <div className="content-card">
          <h2>{name}</h2>
        </div>
      </div>
    </a>
  );
};

export default Card;
