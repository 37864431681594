const serviceMetaTags = [
  {
    link: "Wax-Bath",
    metaTitle: "Wax Bath Therapy - MS Thera-Cure Clinic Shyamnagar, Kolkata",
    metaDescription: "Discover holistic pain management with wax bath therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Effective treatment for various pain conditions."
  },
  {
    link: "Long-Wave-Therapy",
    metaTitle: "Long Wave Therapy - MS Thera-Cure Clinic Shyamnagar, Kolkata",
    metaDescription: "Discover comprehensive musculoskeletal care with Long Wave Therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata, enhancing mobility and overall wellness."
  },
  {
    link: "Mobilisations",
    metaTitle: "Mobilisations Service - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Enhance joint mobility and alleviate pain at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our skilled therapists offer personalized mobilizations for holistic well-being."
  },
  {
    link: "Ultrasound-Therapy",
    metaTitle: "Ultrasound Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience personalized ultrasound therapy in Shyamnagar, Kolkata. Discover innovative, targeted treatment modalities for enhanced well-being."
  },
  {
    link: "Interferential-Therapy",
    metaTitle: "Interferential Therapy (IFT) - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience the therapeutic benefits of IFT at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Personalized electrotherapy for enhanced well-being."
  },
  {
    link: "TENS",
    metaTitle: "TENS Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience pain relief with TENS therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Non-invasive, tailored solutions for acute and chronic pain."
  },
  {
    link: "EMS-Therapy",
    metaTitle: "EMS (Electronic Muscle Stimulation) Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover the benefits of EMS (Electronic Muscle Stimulation) Therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our expert team offers personalized treatments for enhanced muscle health and well-being."
  },
  {
    link: "LASER",
    metaTitle: "Low-Level Laser Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover the latest in physiotherapy with Low-Level Laser Therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Experience optimal healing."
  },
  {
    link: "Gait-Training",
    metaTitle: "Gait Training - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience personalized Gait Training in Shyamnagar, Kolkata. Transform your walking pattern and quality of life with our dedicated team."
  },
  {
    link: "Balance-Training",
    metaTitle: "Balance Training - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover the therapeutic benefits of Balance Training at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Achieve better mobility & stability with personalized sessions."
  },
  {
    link: "Dry-Needling-Therapy",
    metaTitle: "Dry Needling Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience effective pain relief with dry needling therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our experts target trigger points to enhance your well-being."
  },
  {
    link: "Manual-Therapy",
    metaTitle: "Manual Therapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover transformative Manual Therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our skilled therapists offer hands-on care to enhance your musculoskeletal well-being."
  },
  {
    link: "Post-Operative-Physiotherapy",
    metaTitle: "Post-Operative Physiotherapy - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Experience personalized post-operative physiotherapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our expert team aids your swift, smooth recovery journey."
  },
  {
    link: "PNF",
    metaTitle: "Proprioceptive Neuromuscular Facilitation (PNF) - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover PNF therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Enhance movement and promote well-being with our innovative neurophysiological approach."
  },
  {
    link: "Motor-Relearning-Programme",
    metaTitle: "Motor Relearning Programme - MS Thera-Cure Clinic, Shyamnagar, Kolkata",
    metaDescription: "Discover enhanced motor control at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Our Motor Relearning Programme offers personalized, task-oriented sessions for improved daily activities."
  }
];

export default serviceMetaTags;
