import React from "react";
import { Services } from "./ServicesInfo";
import Navbar from '../Navbar'
import Footer from '../Footer'
import Card from "./Card";
const Service = () => {

  return (
    <div style={{ marginBottom: '40px' }}>
      <Navbar />
      <div className='service-header' style={{ marginBottom: '40px' }}>
        <h3>Services</h3>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px', marginBottom: '40px' }}>
        {Services.map((service, index) => {
          return <Card key={index} imageUrl={service.img} name={service.name} link={service.link} />
        })}
      </div>
      <Footer />
    </div >
  );
};

export default Service;
