import React from "react";
import "../styles/hero.css";
import BusinessInfo from "./InformationCard/BusinessInfo";
import ImageSlider from "../pages/ImageSlider";
import { hero } from "../helper/slideInfo";
const Hero = () => {
  return (
    <section id="hero">
      <div className="hero">
        <div className="hero-content">
          <h1>
          Leading provider for pain relief and wellness
          </h1>
          <p>
            MS THERA-CURE CLINIC STARTED ITS JOURNEY IN SERVING THE HUMANITY ON 15TH AUGUST 2019. IT’S BEEN 5 YEARS SINCE WE HAVE SERVED THE SOCIETY WITH OUR DEDICATED AND EMINENT PHYSIOTHERAPISTS. OVER A SPAN OF 5 YEARS, WE HAVE TREATED MORE THAN 1000 PATIENTS.
          </p>
        </div>
        <div className="imageSlider">
          <ImageSlider images={hero} autoPlay={true} maxImg={1} imageName={"imageHero"} />
        </div>

      </div>
      <BusinessInfo />
    </section>
  );
};

export default Hero;
