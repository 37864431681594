import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { gallery } from './GalleryInfo'
import { useLocation } from 'react-router-dom'

function GalleryDetail() {
    const [info, setInfo] = useState('')
    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');


    useEffect(() => {
        setLoading(true)
        // eslint-disable-next-line array-callback-return
        gallery.map((item) => {
            if (item.name === name) {
                setInfo(item);
            }
        })
        setLoading(false)

    }, [name])
    return (
        <div>
            <Navbar />
            <div className='gallery-header'>
                <h3>{name}</h3>
            </div>
            <div className='gallery-folder'>
                {info?.images?.map((img, index) => {
                    return <div key={index} className='gallery-card'>
                        <img src={img} alt={index}></img>
                    </div>
                })}
            </div>
            <Footer />
        </div>
    )
}

export default GalleryDetail