import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from "react-router-dom";

function CareerContainer({ data }) {
  const navigate = useNavigate();

  return (
    <div className="career-container">
      <Box component="form"
        onClick={() => navigate(`/careers/apply?id=${data.jobId}`)}
        noValidate
        autoComplete="off" sx={{ '& > :not(style)': { m: 1 }, cursor: "pointer" }}>
        <div className='spaceBetween'>
          <h4 className='jobname'>{data.name}</h4>
          <div>
            Posted on {data.createdAt.substring(0, 10)}
          </div>
        </div>
        <div className='spaceBetween'>
          <div className='flexstart'>
            <Box sx={{ marginRight: "20px" }}>Job Id: {data.jobId} </Box>
            <Box sx={{ marginRight: "20px" }}>Location: {data.location}</Box>
            <Box sx={{ marginRight: "20px" }}>Mode: {data.mode}</Box>
          </div>
          {data?.endDate && <div>
            <Box >End Date: {data.endDate}</Box>
          </div>}
        </div>
        <div>
          Description
          <div style={{ textAlign: 'justify' }} >{data.description}</div>
        </div>
        {data?.responsibilities.length > 0 && < div >
          Responsibilities
          < div style={{ textAlign: 'justify' }}>{data.responsibilities}</div>
        </div >}
        {data?.requirement.length > 0 && < div >
          Requirements
          < div style={{ textAlign: 'justify' }}>{data.requirement}</div>
        </div >}
        {data?.qualifications.length > 0 && < div >
          Qualifications
          < div style={{ textAlign: 'justify' }}>{data.qualifications}</div>
        </div >}
      </Box >
    </div >
  )
}

export default CareerContainer