import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Contact from "../components/Contact";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Appointments from './Appointments';
import Appointment from "./Appointment";
import WhyUs from "../components/WhyUs";
import fetchData from "../helper/apiCall";
import jwt_decode from "jwt-decode";
import Dashboard from "./Dashboard";
import Loading from "../components/Loading";
import PopUp from "../components/Modal/PopUp";
import '../styles/home.css'

const Home = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL
  const token = localStorage.getItem("token");
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true);
  const pathname = window.location.pathname
  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const getUser = async (id) => {
      try {
        const temp = await fetchData(`${baseUrl}/user/getuser/${id}`);
        setUser(temp);
        setLoading(false)
        openModal()
      } catch (error) {
        setLoading(false)
        return error;
      }
    };
    if (token) {
      setLoading(true)
      getUser(jwt_decode(token).userId)
    }

  }, [baseUrl, token])

  useEffect(() => {
    let section = ''
    if (pathname === "/contact") {
      section = document.getElementById("contact");

    } else if (pathname === '/about') {
      section = document.getElementById("about");
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        navigate('/');
      }, 3000)
    }
  }, [pathname]);


  return (
    <>
      {loading ? <Loading /> :
        !user ?
          <>
            <Navbar />
            <PopUp isOpen={isModalOpen} onRequestClose={closeModal} />
            <Hero />
            <WhyUs />
            <div className="contactappoint">
              <Appointment />
              <Contact />
            </div>

            <AboutUs />
            <Footer />
          </>
          : user.isAdmin ? <Dashboard type={"users"} /> : <Appointments />
      }
    </>
  );
};

export default Home;
