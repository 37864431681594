import React from 'react'
import appointment from '../images/appointment3.jpg'
import Navbar from './Navbar'
import '../styles/appointment.css';
import { Button } from '@mui/material';

function AppointmentSteps() {
    return (
        <>
            <Navbar />
            <div className='appointmentSteps'>
                <div className='appointmentStepsleft'>
                    <img src={appointment} alt="appointment"></img>
                </div>
                <div className='appointmentStepsright'>
                    <div className='appointmentStepsrightleft'>
                        <div className='circle1'></div>
                        <div className='circle2'>1</div>
                        <div className='circle2'>2</div>
                        <div className='circle2'>3</div>
                        <div className='circle2'>4</div>
                        <div className='circle2'>5</div>
                        <div className='circle2'>6</div>
                        <div className='circle3'></div>
                    </div>
                    <div className='appointmentStepsrightright'>
                        <h3>Steps to book appointment</h3>
                        <h5>Create a new account</h5>
                        <h5>Login into your account.</h5>
                        <h5>Navigate to the appointment page.</h5>
                        <h5>Select the desired appointment slot and proceed to book it.</h5>
                        <h5>You will receive a confirmation email after booking.</h5>
                        <h5>Wait for the doctor to confirm or cancel the appointment.</h5>
                        <div>
                            <Button variant="outlined" sx={{ marginRight: "20px" }} href='/register'>SignUp</Button>
                            <Button variant="contained" href='/login'>Login</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentSteps