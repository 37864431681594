import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import Loading from '../Loading'
import { useNavigate } from "react-router-dom";

function CareerApply() {
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(false);

    // const [selectedFile, setSelectedFile] = useState(null);
    const [formdata, setFormData] = useState({ name: "", email: "", phone: "", document: null })
    const [formerror, setFormError] = useState({ name: false, email: false, phone: false, document: false })
    const [file, setFile] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get('id');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    const handleFormData = (e) => {
        const { name, value } = e.target
        const newformdata = { ...formdata };
        newformdata[name] = value
        setFormData(newformdata)
    }


    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    };

    const handleJobApply = async () => {
        let isError = false;
        const newError = { ...formerror };
        console.log(formdata)
        if (!formdata.name.length) {
            newError.name = true;
            isError = true
        }

        if (!formdata.email.length) {
            newError.email = true;
            isError = true
        }

        if (!formdata.phone.length) {
            newError.phone = true;
            isError = true
        }

        if (!file) {
            newError.mode = true;
            isError = true
        }

        setFormError(newError)

        if (isError) return;
        if (job?.endDate.length) {
            const today = new Date();
            const targetDate = new Date(job.endDate);
            if (today > targetDate) {
                toast.error('Job expired');
                return
            }
        }
        const form = new FormData();
        form.append('file', file);
        form.append('name', formdata.name);
        form.append('email', formdata.email);
        form.append('phone', formdata.phone);
        await toast.promise(
            axios.post(`${baseUrl}/career/uploadCareerData`, form),
            {
                pending: "Submitting...",
                success: "Submitted",
                error: "Unable to submit",
                loading: "Submitting...",
            }
        );

        navigate(`/careers`)

    }

    useEffect(() => {
        setLoading(true)
        const getjobDetail = async () => {
            const job = await axios.post(`${baseUrl}/career/getjobDetail`, {
                jobId
            })
            setJob(job.data)
        }
        getjobDetail()
        setLoading(false)
    }, [baseUrl, jobId])

    return (
        <div>
            <Navbar />
            {loading ? <Loading /> :
                <Box component="form"
                    noValidate
                    className="apply-container"
                    autoComplete="off" sx={{ '& > :not(style)': { m: 1 }, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
                    <h3>Applying for {job?.name}</h3>
                    <p>Job Id: {job?.jobId}</p>
                    <TextField id="outlined-basic" label="Name" variant="outlined" required error={formerror.name} sx={{ width: "100%", margin: "10px" }} name="name" onChange={(e) => handleFormData(e)} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" required error={formerror.email} sx={{ width: "100%", margin: "10px" }} name="email" multiline onChange={(e) => handleFormData(e)} />
                    <TextField id="outlined-basic" label="Phone No" variant="outlined" required error={formerror.phone} sx={{ width: "100%", margin: "10px" }} name="phone" multiline onChange={(e) => handleFormData(e)} />
                    <TextField id="outlined-basic" label="Upload Resume" type="file" required onChange={handleFileChange} sx={{ width: "100%" }} error={formerror.name} />
                    <Button variant='contained' sx={{ width: "100%" }} onClick={handleJobApply}>Submit</Button>
                </Box>
            }
            <Footer />
        </div>
    )
}

export default CareerApply