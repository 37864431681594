import React, { useEffect, useState } from 'react'
import Empty from '../Empty';
import { Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import FileDownload from "js-file-download";

function CareerData() {

    const [data, setData] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;


    const handleDownload = async (ele) => {
        console.log(ele.document)
        const res = await axios({
            method: "POST",
            url: `${baseUrl}/career/getDownload`,
            responseType: "blob",
            data: {
                path: ele.document,
            },
        });
        FileDownload(res.data, ele.document.split("@")[1]);
    };
    useEffect(() => {
        const getData = async () => {
            const data = await toast.promise(
                axios.get(`${baseUrl}/career/getappliedjobs`),
                {
                    pending: "Fetching applied job...",
                    success: "Applied Jobs fetched",
                    error: "Unable to fetch applied jobs",
                    loading: "Fetching applied job...",
                }
            );
            data.data.sort((a, b) => {
                const dataA = new Date(a.createdAt)
                const dataB = new Date(b.createdAt)
                return dataB - dataA
            })
            setData(data.data)
        }

        getData()
    }, [baseUrl])


    return (
        <div>
            <section className="" style={{ flex: 6 }}>
                <h3 className="page-heading" style={{ marginTop: "20px" }}>All Job Data</h3>
                {/* <div className="search">
                    <div >
                        <p>Search By Date</p>
                        <input
                            type="date"
                            placeholder="Search By Date"
                            className="form-input"
                            onChange={(e) => setfilterDate(e.target.value)}
                            value={filterDate}
                        />
                    </div>
                    <button
                        className="btn user-btn"
                        style={{ marginLeft: "10px", marginRight: "20px", marginBottom: "10px" }}
                        onClick={() => setfilterDate("")}
                    >
                        Clear
                    </button>
                </div> */}
                {data.length > 0 ? (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Email Id</th>
                                        <th>Phone No</th>
                                        <th>Document</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((ele, i) => {
                                        return (
                                            <tr key={ele._id}>
                                                <td>{i + 1}</td>
                                                <td>
                                                    {ele.name}
                                                </td>
                                                <td>{ele.email}</td>
                                                <td>{ele.phone}</td>
                                                <td><Button onClick={() => handleDownload(ele)}>Download</Button> </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <Empty />
                )}
            </section>
        </div>
    )
}

export default CareerData