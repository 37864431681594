import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./InformationCard.css";

const InformationCard = ({ info }) => {
  return (
    <Link to={info.to} target={info.to !== "/" ? "_blank" : ""}>
      <div className="text-white info-card">
        <div className={`info-container info-${info.background}`}>
          <div>
            <FontAwesomeIcon
              className="info-icon"
              icon={info.icon}
            ></FontAwesomeIcon>
          </div>
          <div className="text-div">
            <h6 className="text-white">{info.title}</h6>
            {info.title === "Opening Hours" && (
              <div>
                <h6 style={{ color: "white" }}>MORNING - 9 AM TO 1 PM</h6>
                <h6 style={{ color: "white" }}>EVENING - 5 PM TO 8 PM</h6>
              </div>
            )}
            <small>{info.description}</small>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default InformationCard;
