import gallery1 from "../images/gallery.jpg";
import gallery2 from "../images/gallery2.jpg";
import gallery3 from "../images/gallery3.jpg";
import gallery4 from "../images/gallery4.jpg";
import gallery5 from "../images/gallery5.jpg";
import gallery6 from "../images/gallery6.jpg";
import gallery7 from "../images/gallery7.jpg";
import gallery8 from "../images/gallery8.jpg";
import gallery9 from "../images/gallery9.jpg";
import hero1 from "../images/hero1.JPG";
import hero2 from "../images/hero2.JPG";
import hero3 from "../images/hero3.JPG";
import hero4 from "../images/hero4.JPG";
import hero5 from "../images/hero5.JPG";
import hero6 from "../images/hero6.JPG";

export const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
];

export const hero = [hero1, hero2, hero3, hero4, hero5, hero6];
