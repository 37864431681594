import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import axios from 'axios';

function Pop() {
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [allPopUp, setAllPopUp] = useState([]);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setFile(null);
        setImagePreview(null);
    };

    const handleRemoveUploadedImage = async (pop, index) => {
        try {
            const images = [...allPopUp].slice(0, index).concat([...allPopUp].slice(index + 1));
            setAllPopUp(images);
            await axios.post(`${baseUrl}/user/removePopupImage`, { id: pop._id, imagePath: pop.filepath });
        } catch (error) {
            console.error('Error uploading pop-up image:', error);
        }
    };
    const handleUploadImage = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const res = await axios.post(`${baseUrl}/user/pop-up`, formData);
                const images = [res.data.image, ...allPopUp]
                setAllPopUp(images)
                setImagePreview(null);
                setFile(null)
            } catch (error) {
                console.error('Error uploading pop-up image:', error);
            }
        }
    };

    useEffect(() => {
        const fetchPopupImage = async () => {
            try {
                const response = await axios.get(`${baseUrl}/user/getPopupImage`);
                setAllPopUp(response.data.images.reverse())
            } catch (error) {
                console.error('Error fetching pop-up image:', error);
            }
        };
        fetchPopupImage();
    }, [baseUrl]);

    return (
        <div style={{ margin: '20px' }}>
            <h4 style={{ marginBottom: '10px' }}>Upload a pop up image</h4>
            <TextField
                id="outlined-basic"
                label=""
                type="file"
                required
                onChange={handleFileChange}
                sx={{ width: "50%" }}
                error={false}
                inputProps={{
                    accept: "image/*"
                }}
            />
            {imagePreview && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ marginTop: '30px' }}>Preview Uploaded Image</h4>
                    <img src={imagePreview} alt="Uploaded" style={{ maxWidth: '50%', height: 'auto', marginTop: '40px' }} />
                    <div>
                        <Button onClick={handleRemoveImage} variant="outlined" color="secondary" style={{ marginTop: '10px' }}>Remove Image</Button>
                        <Button onClick={handleUploadImage} variant="contained" color="primary" style={{ marginLeft: '10px', marginTop: '10px' }}>Save Image</Button>
                    </div>
                </div>
            )}
            <h4 style={{ marginTop: '30px' }}>ALL Uploaded Pop Up Images (The latest one will be used for pop up)</h4>
            {allPopUp?.map((pop, index) => {
                const imgUrl = baseUrl.replace('/api', '');
                const srcImg = `${imgUrl}/uploads/${pop.filename}`;
                return <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={srcImg} alt="Uploaded" style={{ maxWidth: '50%', height: 'auto', marginTop: '40px' }} />
                    <div>
                        <Button onClick={() => handleRemoveUploadedImage(pop, index)} variant="outlined" color="secondary" style={{ marginTop: '10px' }}>Remove Image</Button>
                    </div>
                </div>
            })}
        </div>
    )
}

export default Pop;
