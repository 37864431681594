import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import fetchData from "../helper/apiCall";
import toast from "react-hot-toast";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function AppointmentBookingDetail({ booking, open, handleClose }) {
    const [patient, setPatient] = useState("");
    const [appointmentdate, setAppointmentDate] = useState("");
    const [appointments, setAppointments] = useState([]);
    const { userId } = jwt_decode(localStorage.getItem("token"));
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const getAllAppoint = async (e) => {
        try {
            const temp = await fetchData(
                `${baseUrl}/appointment/getallappointments?search=${userId}`
            );
            setAppointments(temp);
        } catch (error) { }
    };

    const handlebook = async () => {
        if (patient === "" || appointmentdate === "") {
            alert("Incomplete data");
            return;
        }
        let date = new Date()
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const bookingdate = [year, month, day].join('-')
        if (new Date(appointmentdate) < new Date(bookingdate)) {
            alert("Can't book in previous dates")
            return;
        }
        for (let app of appointments) {
            if (app.appointmentdate === appointmentdate) {
                alert("Already booked for this date");
                return;
            }
        }
        const { data } = await toast.promise(
            axios.post(
                `${baseUrl}/appointment/bookappointment`,
                {
                    userId,
                    patient,
                    appointmentdate,
                    appointmenttime: booking.time,
                    bookingdate
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }), {
            pending: "Booking Appointment...",
            success: "Booking Successful",
            error: "Can't booked",
            loading: "Booking Appointment...",
        }
        )
        handleClose()
    }
    useEffect(() => {
        getAllAppoint();
    }, []);
    return (
        <div><Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Fill the details
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <input onChange={e => setPatient(e.target.value)} placeholder='Patient Name' style={{ width: "100%", padding: "10px", marginBottom: "10px" }}></input>
                    <input onChange={e => setAppointmentDate(e.target.value)} type='date' placeholder='Date of Appointment' style={{ width: "100%", padding: "10px", marginBottom: "10px" }}></input>
                    <input value={booking.time} placeholder='Time of Appointment' readOnly style={{ width: "100%", padding: "10px", marginBottom: "10px" }}></input>
                </Typography>
                <button style={{ padding: "10px", marginRight: "20px " }} className="btn btn-primary" onClick={handlebook}>Book</button>
                <button style={{ padding: "10px", marginLeft: "20px ", backgroundColor: "red" }} className="btn btn-danger" onClick={handleClose}>Cancle</button>
            </Box>
        </Modal></div>
    )
}

export default AppointmentBookingDetail