import React from 'react';
import Navbar from '../components/Navbar';
import { festivals } from '../constant/holiday';
import Footer from '../components/Footer';
import { Grid, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import '../styles/holiday.css';

const Holiday = () => {
    return (
        <div>
            <Navbar />
            <div className='holiday-container'>
                <h2 className='holiday-heading'>LIST OF HOLIDAYS FOR THE YEAR 2024</h2>
                <Grid container justifyContent="center">
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Paper className='holiday-table-container'>
                            <TableContainer>
                                <Table className='holiday-table'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: 15 }}>SL NO.</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: 15 }}>Festival</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: 15 }}>Number of Days</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: 15 }}>Day of the Week</TableCell>
                                            <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: 15 }}>English Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {festivals.map((festival, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ textAlign: 'center', fontSize: 15 }}>{index + 1}</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontSize: 15 }}>{festival.name}</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontSize: 15 }}>{festival.numberOfDays}</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontSize: 15 }}>{festival.dayOfWeek}</TableCell>
                                                <TableCell style={{ textAlign: 'center', fontSize: 15 }}>{festival.englishDate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid><div className="holiday-note">
                    <p>
                        <strong>Note:</strong>
                    </p>
                    <ul>
                        <li>Sundays are holidays under the Negotiable Instrument Act.</li>
                        <li>
                            It may happen that the change of date of the abovementioned occasions has
                            to be notified at a very short notice, in such a situation, an announcement would
                            be made through the website and WhatsApp.
                        </li>
                        <li>
                            No substitute holiday shall be allowed if any of the festival-holidays initially
                            notified subsequently happens to fall on a weekly off or any other non-working day
                            or in the event of more than one festival falling on the same day.
                        </li>
                        <li>
                            If there is any change in the date of the festivals/occasions, then the holiday
                            will be declared by a separate order for the date on which the festival would be
                            actually observed in lieu of the date originally notified as a holiday for this festival.
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Holiday;
