/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import './Service.css'
import Footer from '../Footer';
import Loading from '../Loading';
import { serviceNewInfo } from './ServiceNewInfo';
import List from './List';
import FAQ from '../FAQ/FAQ';
import { Helmet } from 'react-helmet';
import serviceMetaTags from './MetaTags';

function ServiceDetails() {
    const [info, setInfo] = useState('')
    const [metaData, setMetaData] = useState({})

    const [loading, setLoading] = useState(false)

    const { servicesType } = useParams(); // Access the parameter using useParams()


    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line array-callback-return
        serviceNewInfo.map((item) => {
            if (item.link === servicesType) {
                setInfo(item);
            }
        });
        serviceMetaTags.forEach(tag=>{
            if(tag.link===servicesType){
                setMetaData(()=>tag);
            }
        })

        setLoading(false);
    }, [servicesType]);

    return (
        <div>
            <Navbar />
            <Helmet>
        <title>{metaData?.metaTitle}</title>
        <meta name="description" content={metaData?.metaTitle}></meta>
      </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className='service-header'>
                        <h3>{info?.title}</h3>
                    </div>
                    <div className='service-content'>
                        <p>{info?.description}</p>
                        {info?.description1?.map((desc, index) => {
                            return <List key={index} name={desc.name} description={desc.description} />
                        })}
                        {info?.keyFeatures?.length && <div style={{ marginTop: "15px" }}> <strong > Key Features of {info?.title} </strong></div>}
                        {info?.keyFeatures?.map((keyf, index) => (
                            <List key={index} name={keyf.name} description={keyf.description} />
                        ))}
                        {info?.application?.map((app, index) => {
                            return <div key={index} style={{ marginTop: '15px' }}>
                                <strong>{app?.name}</strong>
                                <p>{app.description}</p>
                            </div>
                        })}
                        <div style={{ marginTop: "15px" }}>  <strong>Therapeutic Benefits</strong></div>
                        {info?.therapeuticBenefits?.map((d, index) => (
                            <List key={index} name={d.name} description={d.description} subList={d.subDescription} />
                        ))}
                        {info?.endDes?.map((ed, index) => {
                            return <p style={{ marginTop: '15px' }} key={index}>{ed}</p>
                        })}
                    </div>
                </>
            )}
            <FAQ name={info?.title} />
            <Footer />
        </div>
    );
}

export default ServiceDetails