import {
  faClock,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import InformationCard from "./InformationCard";
import React from "react";

const infosData = [
  {
    title: "Opening Hours",
    description: "We are open Monday to Saturday",
    icon: faClock,
    background: "primary",
    to: "/",
  },
  {
    title: "Visit Our Location",
    description:
      "361/A, Basudevpur Road, Ground Floor, 'Nilanjana' Apartment, Shyamnagar, West Bengal, 743127, India",
    icon: faMapMarker,
    background: "dark",
    to: "https://maps.app.goo.gl/ZEDhEA8J5GKSbte8A",
  },
  {
    title: "Call us now",
    description: "(033) 3564 7255",
    icon: faPhone,
    background: "primary",
    to: "/",
  },
];

const BusinessInfo = () => {
  return (
    <section>
      <div className="business">
        {infosData.map((info) => (
          <InformationCard info={info} key={info.title}></InformationCard>
        ))}
      </div>
    </section>
  );
};

export default BusinessInfo;
