import React from 'react';

function Schema() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "MedicalBusiness",
    "name": "MS Thera Cure",
    "url": "https://www.mstheracure.com/",
    "description": "Leading provider for pain relief and wellness.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": " 361/A, Basudevpur Road, Ground Floor,'Nilanjana' Apartment ",
      "addressLocality": "Shyamnagar",
      "addressRegion": "West Bengal",
      "postalCode": "743127",
      "addressCountry": "Your Country"
    },
    "telephone": "033-3564-7255",
    "openingHours": "Mo, Tu, We, Th, Fr 17:00-21:00",
    "image": "https://www.mstheracure.com/static/media/Logo.bbf1df80f14286b9fdaa.jpg",
    "sameAs": [
      "https://www.facebook.com/theracureclinic/",
      "https://www.instagram.com/thera.cure/",
      "https://www.youtube.com/@TheraCureClinic"
    ],
    "priceRange": "₹₹",
    "currenciesAccepted": "INR",
    "paymentAccepted": "Cash, Credit Card",
    "founder": "Dr. Mainak Sur",
    "foundingDate": "2020-01-01",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "033-3564-7255",
      "contactType": "customer service"
    },
    "hasMap": "https://maps.app.goo.gl/ZEDhEA8J5GKSbte8A",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.mstheracure.com/"
    }
  };

  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </div>
  );
}

export default Schema;
