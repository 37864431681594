import React, { useState } from "react";
import "../styles/contact.css";
import axios from "axios";
import toast from "react-hot-toast";

const Contact = () => {
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    message: "",
  });

  const inputChange = (e) => {
    const { name, value } = e.target;
    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      const { name, email, message } =
        formDetails;
      if (!name || !email || !message) {
        return toast.error("Input field should not be empty");
      }
      console.log(name, email, message);
      const { data } = await toast.promise(
        axios.post(`${baseUrl}/message/newmessage`, {
          name,
          email,
          message,
        }),
        {
          pending: "Sending message...",
          success: "Message sent successfully",
          error: "Unable to send message",
          loading: "Sending message...",
        }
      );
      setFormDetails({
        name: "",
        email: "",
        message: "",
      })

    } catch (error) { }
  };

  return (
    <section
      id="contact"
      style={{ flex: '2', marginLeft: '20px', width: '80%' }}
    >
      <div className="contact">
        <h2 className="contact-heading">Have Queries? Contact Us</h2>
        <form
          onSubmit={formSubmit}
          className="register-form"
        >
          <input
            type="text"
            name="name"
            className="form-input-contact"
            placeholder="Enter your name"
            value={formDetails.name}
            onChange={inputChange}
          />
          <input
            type="email"
            name="email"
            className="form-input-contact"
            placeholder="Enter your email"
            value={formDetails.email}
            onChange={inputChange}
          />
          <textarea
            type="text"
            name="message"
            className="form-input-contact"
            placeholder="Enter your message"
            value={formDetails.message}
            onChange={inputChange}
            rows="8"
            cols="12"
          ></textarea>

          <button
            type="submit"
            className="btn form-btn"
            style={{width:'100%'}}
          >
            send
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
