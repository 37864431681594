import React from 'react'
import '../styles/teams.css'
import team1 from '../images/team1.jpg'
import team2 from '../images/team2.jpg'
import team3 from '../images/team3.jpg'
import team4 from '../images/team4.jpg'
import team5 from '../images/team5.jpg'
import Navbar from './Navbar'
import Footer from './Footer'

const TeamMembers = [
    {
        name: "Dr. Mainak Sur PT",
        pic: team1,
        designation: "Founder and Chief Therapist",
        certification1: "MPT(Neurology) ",
        certification2: "[W.B.U.H.S], COMT, ",
        certification3: "CMFRP, MIAP",
        yoe: "10"
    },
    {
        name: "Dr. Diksha Palit PT",
        pic: team4,
        designation: "Co-Founder & Consultant Physiotherapist",
        certification1: "B.P.T [W.B.U.H.S], CDNT",
        yoe: "1"
    },
    {
        name: "Dr. Puja Saha PT",
        pic: team2,
        designation: "Consultant Physiotherapist",
        certification1: "MPT (Neurology) [W.B.U.H.S]",
        yoe: "2"
    },
    {
        name: "Dr. Diptesh Dey PT",
        pic: team3,
        designation: "Clinical Physiotherapist",
        certification1: "B.P.T [W.B.U.H.S]",
        yoe: "2"
    },
   
    {
        name: "Ms. Rima Bhattacharya",
        pic: team5,
        designation: "Front Office/Desk Management",
        certification1: "B.Sc. (W.B.S.U)",
        yoe: "1"
    },
]

function Teams() {
    return (
        <section id="team">
            <Navbar />
            <div className='teams'>
                <h1>Meet Our Team</h1>
                <div className='teammember'>
                    {TeamMembers.map((member, index) => {
                        return <div key={index} className='teamcard'>
                            <img src={member.pic} alt={member.name} className='cardimg'></img>
                            <h3>{member.name}</h3>
                            <h5>{member.designation}</h5>
                            <h4>{member.certification1}</h4>
                            <h4>{member.certification2}</h4>
                            <h4>{member.certification3}</h4>
                        </div>
                    })}
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Teams