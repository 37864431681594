import React from "react";
import AdminApplications from "../components/AdminApplications";
import AdminAppointments from "../components/AdminAppointments";
import AdminDoctors from "../components/AdminDoctors";
import Sidebar from "../components/Sidebar";
import Users from "../components/Users";
import Message from "../components/Message";
import Careers from "../components/Career/Careers";
import CareerData from "../components/Career/CareerData";
import Pop from "./Pop";

const Dashboard = (props) => {
  const { type } = props;
  return (
    <>
      <section className="layout-section">
        <div className="layout-container">
          <Sidebar />
          <div style={{ flex: 6 }}>
            {type === "users" ? (
              <Users />
            ) : type === "doctors" ? (
              <AdminDoctors />
            ) : type === "applications" ? (
              <AdminApplications />
            ) : type === "appointments" ? (
              <AdminAppointments />
            ) : type === "message" ? (
              <Message />
            ) : type === "careers" ? (
              <Careers isAdmin={true} />
            ) : type === "careers-data" ? (
              <CareerData />
            ) : type === "pop-up" ? (
              <Pop />
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
