export const serviceNewInfo = [
  {
    title: "Wax Bath",
    link: "Wax-Bath",
    description:
      "Wax bath therapy at MS Thera Cure clinic involves the application of molten paraffin wax to the affected distal extremities. This treatment utilizes a form of moist heat to provide various therapeutic benefits.",

    application: [
      {
        name: "Moist Heat Application",
        description:
          "Wax bath therapy is distinguished by its use of moist heat, which contributes significantly to its effectiveness in promoting healing and relief.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Analgesic Properties",
        description:
          "Wax bath therapy acts as a natural analgesic, providing relief from pain and discomfort. The gentle heat helps soothe sore muscles and joints, making it an effective method for managing pain associated with various conditions.",
      },
      {
        name: "Circulatory Effect",
        description:
          "The application of molten paraffin wax enhances blood circulation in the treated areas. Improved circulation is crucial for delivering oxygen and nutrients to the tissues, promoting healing and reducing inflammation.",
      },
      {
        name: "Stretching Effect",
        description:
          "Wax bath therapy also offers a stretching effect, which can be particularly beneficial for individuals with stiffness or limited range of motion. The warmth of the wax helps to relax muscles and tissues, facilitating a more effective stretching experience.",
      },
    ],
    endDes: [
      "Wax bath therapy is a versatile and soothing treatment option offered at MS Thera Cure clinic, aimed at addressing a range of conditions and providing patients with a holistic approach to pain management and rehabilitation.",
    ],
  },
  {
    title: "Long Wave Therapy",
    link: "Long-Wave-Therapy",
    description:
      "Long Wave Therapy at MS Thera Cure clinic involves the therapeutic application of deep heat specifically targeted at muscles. This treatment offers a range of therapeutic benefits aimed at promoting overall well-being and addressing specific health concerns.",
    application: [
      {
        name: "Therapeutic Application:",
        description:
          "Long Wave Therapy focuses on delivering deep heat to targeted muscles, harnessing its therapeutic effects to address various health issues.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Increased Metabolic Functions",
        description:
          "Long Wave Therapy stimulates metabolic functions within the targeted muscles, promoting cellular activity and enhancing overall metabolism. This can contribute to improved tissue repair and regeneration.",
      },
      {
        name: "Increased Blood Supply",
        description:
          "The therapy enhances blood circulation in the treated area, ensuring a better supply of oxygen and nutrients to the muscles. Improved blood flow aids in the healing process and reduces inflammation.",
      },
      {
        name: "Muscle Relaxant",
        description:
          "Long Wave Therapy acts as a natural muscle relaxant, helping to alleviate muscle tension and spasms. This is particularly beneficial for individuals experiencing muscle tightness or discomfort.",
      },
      {
        name: "Improved Range of Motion",
        description:
          "By promoting muscle relaxation and reducing stiffness, Long Wave Therapy contributes to an improved range of motion. This can be especially advantageous for individuals recovering from injuries or dealing with conditions that affect mobility.",
      },
      {
        name: "Analgesic Properties",
        description:
          "The therapy acts as an analgesic, providing relief from pain and discomfort. The deep heat penetrates tissues, soothing pain and contributing to a more comfortable and relaxed state",
      },
    ],
    endDes: [
      "Long Wave Therapy is a comprehensive and effective approach offered at MS Thera Cure clinic, addressing both the symptoms and underlying causes of various musculoskeletal issues. Patients can benefit from the therapy's multifaceted effects, supporting their journey toward improved mobility and overall wellness",
    ],
  },
  {
    title: "Mobilisations",
    link: "Mobilisations",
    description:
      "At MS Thera Cure Clinic, our Mobilisations service is dedicated to enhancing joint mobility and alleviating pain through skilled, passive movements performed by our experienced physical therapists. We understand the importance of normalized joint function for individuals managing Multiple Sclerosis (MS).",
    therapeuticBenefits: [
      {
        name: "Normalized Joint Function",
        description:
          "Mobilisations contribute to the normalization of joint function, ensuring smoother, more efficient movement.",
      },
      {
        name: "Pain Reduction",
        description:
          "Experience relief from discomfort as Mobilisations effectively work to reduce pain associated with joint issues.",
      },
      {
        name: "Improved Range of Motion",
        description:
          "Enhance your flexibility and range of motion with targeted Mobilization techniques.",
      },
      {
        name: "Increased Joint Nutrition",
        description:
          "Mobilisations promote improved joint nutrition, supporting the health and vitality of the affected areas.",
      },
      {
        name: "Muscle Spasm Improvement",
        description:
          "Address muscle spasms effectively as Mobilisations play a crucial role in relaxing and improving muscle function.",
      },
    ],
    endDes: [
      "At MS Thera Cure Clinic, our Therapeutic Benefits extend beyond symptom relief, focusing on your holistic well-being. Discover the positive impact of Mobilizations on your journey towards improved health and mobility.",
    ],
  },
  {
    title: "Ultrasound Therapy",
    link: "Ultrasound-Therapy",
    description:
      "Ultrasound Therapy stands as a pillar in our commitment to providing innovative and effective physical therapy. Our skilled therapists employ sound waves with a frequency of 0.7 to 3.3 MHz to deliver precise and targeted therapeutic benefits, enhancing your overall well-being.",
    keyFeatures: [
      {
        name: "Precision Sound Waves",
        description:
          "Utilizing advanced sound wave technology for non-invasive and effective treatment.",
      },
      {
        name: "Therapeutic Ultrasound",
        description:
          ": A cutting-edge modality integrated into our comprehensive care for optimal healing.",
      },
    ],
    application: [
      {
        name: "",
        description:
          "Discover the transformative effects of Ultrasound Therapy at MS Thera Cure Clinic. Experience personalized and advanced treatment modalities to enhance your physical therapy journey.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Increased Tissue Perfusion (Oxygenation)",
        description:
          "Promotes enhanced tissue oxygenation, creating an optimal environment for healing.",
      },
      {
        name: "Improved Blood Flow",
        description:
          "Boosts circulation, facilitating nutrient transport and supporting overall tissue health.",
      },
      {
        name: "Inflammatory Conditions",
        description:
          "Alleviates discomfort associated with inflammatory conditions, contributing to improved symptom management.",
      },
      {
        name: "Enhanced Tissue Elasticity",
        description:
          "Promotes flexibility and elasticity in tissues, enhancing overall functional capacity.",
      },
      {
        name: "Pain Reduction",
        description:
          "Effectively reduces pain, providing relief and comfort during the healing process.",
      },
    ],
  },
  {
    title: "Interferential Therapy (IFT)",
    link: "Interferential-Therapy",
    description:
      "Interferential Therapy (IFT) is an electrotherapy modality employed at MS Thera Cure Clinic, harnessing the therapeutic aspects of low-frequency currents without causing discomfort.",
    keyFeatures: [
      {
        name: "Low-Frequency Currents",
        description:
          "Utilizing therapeutic low-frequency currents for effective treatment.",
      },
      {
        name: "Discomfort-Free",
        description:
          "Ensuring a comfortable experience for our patients during Interferential Therapy sessions.",
      },
    ],
    application: [
      {
        name: "",
        description:
          "Discover the transformative effects of Interferential Therapy at MS Thera Cure Clinic. Explore how this electrotherapy modality can contribute to your overall well-being",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Muscle Stimulation",
        description:
          "Interferential Therapy is beneficial for targeted muscle stimulation, aiding in strength and function.",
      },
      {
        name: "Increased Local Blood Flow",
        description:
          "Promoting enhanced blood circulation in the targeted area for improved tissue health.",
      },
      {
        name: "Conditions Suitable for IFT",
        description: "",
        subDescription: [
          {
            name: "Stump Pain (Post Amputation)",
            description:
              "Addressing discomfort associated with post-amputation stump pain.",
          },
          {
            name: "Vascular Insufficiency",
            description:
              "Offering therapeutic support for individuals with vascular insufficiency.",
          },
          {
            name: "Reflex Sympathetic Dystrophy",
            description:
              "Providing relief for those dealing with Reflex Sympathetic Dystrophy.",
          },
          {
            name: "Myofascial Pain Syndrome",
            description:
              "Targeting pain associated with Myofascial Pain Syndrome.",
          },
        ],
      },
    ],
    endDes: [
      "Experience the therapeutic benefits of Interferential Therapy at MS Thera Cure Clinic. Our dedicated team is committed to enhancing your well-being through personalized and effective electrotherapy treatments.",
    ],
  },
  {
    title: "TENS (Transcutaneous Electrical Nerve Stimulation)",
    link: "TENS",
    description:
      "TENS, or Transcutaneous Electrical Nerve Stimulation, is a low-frequency electrical current therapy utilized at MS Thera Cure Clinic to stimulate peripheral nerves using skin surface electrodes, aiming to relieve pain, whether acute or chronic.",
    keyFeatures: [
      {
        name: "Low-Frequency Electrical Current",
        description:
          "Harnessing the power of low-frequency electrical currents for effective pain relief.",
      },
      {
        name: "Non-Invasive",
        description:
          "TENS is a non-invasive procedure, ensuring a comfortable experience for our patients.",
      },
    ],
    application: [
      {
        name: "",
        description:
          "Discover the pain-relieving benefits of TENS at MS Thera Cure Clinic. Explore how this noninvasive therapy can play a crucial role in your pain management",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Pain Reduction",
        description:
          "TENS is highly effective in reducing both acute and chronic pain, offering relief to individuals dealing with various pain conditions.",
      },
      {
        name: "Beneficial in Radiculopathies",
        description:
          "TENS is particularly beneficial in addressing radiculopathies, including Carpal Tunnel Syndrome and Piriformis Syndrome (Sciatica).",
        subDescription: [
          {
            name: "Carpal Tunnel Syndrome",
            description:
              "Providing targeted relief for individuals experiencing pain associated with Carpal Tunnel Syndrome.",
          },
          {
            name: "Piriformis Syndrome (Sciatica)",
            description:
              "Alleviating discomfort related to Piriformis Syndrome, commonly associated with Sciatica.",
          },
        ],
      },
      {
        name: "Post-Operative Pain",
        description:
          "TENS serves as a valuable tool in managing post-operative pain, promoting a smoother recovery process",
      },
    ],
    endDes: [
      "Experience the therapeutic benefits of TENS at MS Thera Cure Clinic. Our dedicated team is committed to providing you with non-invasive and effective pain relief strategies tailored to your unique needs.",
    ],
  },
  {
    title: "EMS (Electronic Muscle Stimulation)",
    link: "EMS-Therapy",
    description:
      "EMS, or Electronic Muscle Stimulation, is a specialized therapy employed at MS Thera Cure Clinic to target motor nerves and induce muscle responses through electrical muscle stimulation.",
    keyFeatures: [
      {
        name: "Target Motor Nerve Targeting",
        description:
          "Utilizing electronic muscle stimulation to precisely target motor nerves and initiate muscle responses.",
      },
    ],
    application: [
      {
        name: "",
        description:
          "Discover the transformative effects of EMS at MS Thera Cure Clinic. Explore how this specialized therapy can contribute to improved muscle function and overall well-being.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Muscle Re-Education",
        description:
          "EMS aids in re-educating muscles, particularly beneficial after injury or surgery for restoring strength and function.",
      },
      {
        name: "Improved Muscle Function and Tone",
        description:
          "Enhances muscle function and tone through targeted electrical stimulation, promoting rehabilitation and performance improvement.",
      },
      {
        name: "Wound Healing Support",
        description:
          "Facilitates wound healing through improved blood circulation and tissue regeneration, accelerating the recovery process.",
      },
      {
        name: "Increased Range of Motion",
        description:
          "Helps improve joint flexibility and range of motion, essential for functional mobility and injury prevention.",
      },
      {
        name: "Prevention of Muscle Atrophy",
        description:
          "EMS plays a crucial role in preventing muscle atrophy, particularly in individuals with limited mobility or immobilization.",
      },
    ],
    endDes: [
      "Experience the therapeutic benefits of EMS at MS Thera Cure Clinic. Our dedicated team is committed to providing you with personalized and effective electronic muscle stimulation for improved muscle health and overall well-being.",
    ],
  },
  {
    title: "LASER",
    link: "LASER",
    description:
      "LASER, or Low-Level Laser Therapy, stands as one of the most recent therapeutic modalities available at MS Thera Cure Clinic. This advanced treatment involves the production of a unique beam of radiation, distinct from ordinary light.",
    keyFeatures: [
      {
        name: "Recent Therapeutic Modality",
        description:
          "Embracing the latest in therapeutic technology, LASER is a cutting-edge option for our patients.",
      },
      {
        name: "Distinctive Beam of Radiation",
        description:
          "LASER produces a specialized beam of radiation with therapeutic properties.",
      },
    ],
    application: [
      {
        name: "",
        description:
          "Discover the transformative effects of LASER therapy at MS Thera Cure Clinic. Explore how this advanced modality can contribute to your overall well-being.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Analgesic Effect",
        description:
          "Provides effective pain relief through the stimulation of endorphin release, offering comfort to individuals dealing with various pain conditions.",
      },
      {
        name: "Anti-Inflammatory Effect",
        description:
          "Reduces inflammation and swelling, promoting tissue healing and restoring normal function to affected areas.",
      },
      {
        name: "Improved Blood Circulation",
        description:
          "Enhances blood flow to the treated area, facilitating nutrient delivery and waste removal for improved tissue health.",
      },
      {
        name: "Promotes Faster Wound Healing",
        description:
          "Accelerates the wound healing process by stimulating cellular activity and collagen production, resulting in faster recovery.",
      },
      {
        name: "Collagen Production",
        description:
          "Stimulates collagen synthesis, essential for tissue repair, regeneration, and overall skin health.",
      },
    ],
    endDes: [
      "Experience the therapeutic benefits of LASER therapy at MS Thera Cure Clinic. Our commitment is to provide you with the latest advancements in physiotherapy for optimal healing and wellbeing",
    ],
  },
  {
    title: "Gait Training",
    description: "",
    link: "Gait-Training",
    description1: [
      {
        name: "Understanding Gait",
        description:
          "Gait refers to the manner of walking or moving on foot, encompassing the unique pattern an individual follows in their walk.",
      },
      {
        name: "Need for Gait Training?",
        description:
          "Gait training becomes essential when a patient is walking improperly, and the need to correct and optimize their walking pattern arises.",
      },
      {
        name: "How Gait Training Works?",
        description:
          "Gait training involves the practice of sets of task-oriented exercises. These exercises are designed to strengthen muscles and increase the range of motion, ultimately improving the patient's walking pattern.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Gait Normalization",
        description:
          "Improves walking pattern and efficiency, reducing the risk of falls and enhancing overall mobility.",
      },
      {
        name: "Improves Walking Speed",
        description:
          "Increases walking speed and endurance, allowing individuals to cover more distance with less effort.",
      },
      {
        name: "Improves Balance",
        description:
          "Enhances balance and stability during walking, reducing the likelihood of trips and falls.",
      },
      {
        name: "Improves Coordination",
        description:
          "Promotes better coordination between muscles and joints, ensuring smooth, fluid movement.",
      },
    ],
    endDes: [
      "Discover the transformative effects of Gait Training at MS Thera Cure Clinic. Our dedicated team is committed to providing personalized and effective training sessions to enhance your walking pattern, overall mobility, and quality of life.",
    ],
  },
  {
    title: "Balance Training",
    link: "Balance-Training",
    description:
      "Balance refers to the ability to maintain the line of gravity (LOG) within the base of support (BOS). Achieving and maintaining balance is crucial for overall mobility and stability.",
    therapeuticBenefits: [
      {
        name: "Helps with Sensory Problems",
        description:
          "Improves sensory integration, helping individuals better perceive and respond to changes in their environment.",
      },
      {
        name: "Improves Attention",
        description:
          "Enhances attention and focus, reducing the risk of distractions and falls during daily activities.",
      },
      {
        name: "Provides Visual Biofeedback",
        description:
          "Offers visual feedback to individuals, helping them monitor their balance and make necessary adjustments.",
      },
      {
        name: "Aids in Strategy Selection Problems",
        description:
          "Assists individuals in selecting appropriate strategies for maintaining balance in various situations, improving overall stability.",
      },
    ],
    endDes: [
      "Discover the therapeutic benefits of Balance Training at MS Thera Cure Clinic. Our dedicated team is committed to providing personalized and effective training sessions to enhance your balance, stability, and overall well-being.",
    ],
  },
  {
    title: "Dry Needling Therapy",
    link: "Dry-Needling-Therapy",
    description:
      "Dry needling therapy, a sophisticated and targeted intervention practiced at MS Thera Cure Clinic, involves the insertion of a fine needle into specific points in the skin and muscle. This therapeutic approach is designed to address myofascial trigger points, aiming to release tension and promote overall musculoskeletal well-being",
    therapeuticBenefits: [
      {
        name: "Pain Reduction by Counter Irritation and Neurotransmitter Release",
        description:
          "Alleviates pain through various mechanisms, including counter irritation and the release of neurotransmitters like endorphins.",
      },
      {
        name: "Promotes Healing through Fibroblastic Activation",
        description:
          "Activates fibroblasts, promoting tissue repair and regeneration for accelerated healing of injuries and musculoskeletal conditions.",
      },
      {
        name: "Increases Local Blood Supply",
        description:
          "Enhances blood circulation in the targeted area, facilitating nutrient delivery and waste removal, essential for tissue health.",
      },
      {
        name: "Improves Muscle Length and Joint Range of Motion",
        description:
          "Helps improve muscle length and flexibility, reducing stiffness and enhancing joint range of motion for better mobility.",
      },
      {
        name: "Reduces Spasticity by Altering Resting Muscle Length",
        description:
          "Effectively reduces spasticity by altering resting muscle length, providing relief to individuals with conditions like cerebral palsy or stroke.",
      },
    ],
    endDes: [
      "Discover the therapeutic benefits of Dry needle therapy at MS Thera Cure Clinic. Our skilled practitioners are dedicated to providing effective and personalized sessions to enhance your pain relief and overall well-being",
    ],
  },
  {
    title: "Manual Therapy",
    link: "Manual-Therapy",
    description:
      "Manual Therapy is a hands-on technique skillfully employed at MS Thera Cure Clinic to evaluate, treat, and enhance a patient's overall status. This therapeutic approach involves direct physical interaction between the therapist's hands and the patient's body, aiming to address musculoskeletal issues and promote well-being.",
    therapeuticBenefits: [
      {
        name: "Stimulates Biological Activity",
        description: "",
        subDescription: [
          {
            name: "Moves Synovial Fluid",
            description:
              "Manual Therapy facilitates the movement of synovial fluid, promoting the exchange of nutrients to avascular structures within joints.",
          },
          {
            name: "Enhances Nutrient Exchange",
            description:
              "This fluid movement contributes to enhanced nutrient exchange, supporting the health and functionality of joint structures.",
          },
        ],
      },
      {
        name: "Provides Proprioceptive Feedback",
        description: "",
        subDescription: [
          {
            name: "",
            description:
              "Manual Therapy offers proprioceptive feedback, enhancing the body's awareness of its position in space. This feedback is crucial for maintaining balance, coordination, and overall functional movement",
          },
        ],
      },
      {
        name: "Reduces Intraarticular or Periarticular Pressure",
        description: "",
        subDescription: [
          {
            name: "",
            description:
              "The hands-on techniques applied during Manual Therapy effectively reduce intraarticular or periarticular pressure, alleviating discomfort and promoting joint health",
          },
        ],
      },
    ],
    endDes: [
      "Discover the transformative effects of Manual Therapy at MS Thera Cure Clinic. Our skilled therapists are committed to providing hands-on, personalized care to enhance your musculoskeletal well-being and overall quality of life.",
    ],
  },
  {
    title: "Post-Operative Physiotherapy",
    link: "Post-Operative-Physiotherapy",
    description: "",
    description1: [
      {
        name: "Do We Need Post-Operative Physiotherapy?",
        description:
          "Yes, absolutely. Post-operative physiotherapy plays a vital role in preventing respiratory and circulatory complications, contributing to a smoother recovery process.",
      },
    ],
    therapeuticBenefits: [
      {
        name: "Prevention of Post-Operative Chest Complications",
        description: "",
        subDescription: [
          {
            name: "",
            description:
              "Post-operative physiotherapy focuses on exercises and techniques to prevent and alleviate chest complications, ensuring optimal respiratory function.",
          },
        ],
      },
      {
        name: "Prevention of Circulatory Complications",

        description: "",
        subDescription: [
          {
            description:
              "Specialized physiotherapy interventions are designed to prevent circulatory complications, promote healthy blood flow and minimize the risk of related issues",
          },
        ],
      },
      {
        name: "Maintenance of Muscle Power and Joint Range of Motion",
        description: "",
        subDescription: [
          {
            description:
              "Tailored exercises and interventions help maintain muscle power and joint range of motion, preventing stiffness and promoting overall mobility.",
          },
        ],
      },
      {
        name: "Prevention of Pressure Sores",
        description: "",
        subDescription: [
          {
            description:
              "Post-operative physiotherapy includes strategies to prevent pressure sores, particularly important for individuals with limited mobility during the recovery period",
          },
        ],
      },
      {
        name: "Improvement and Enhancement of Bed Mobility",
        description: "",
        subDescription: [
          {
            description:
              "Physiotherapy post-surgery focuses on improving and enhancing bed mobility, ensuring patients can comfortably and safely transition during the recovery phase",
          },
        ],
      },
    ],
    endDes: [
      "Discover the transformative effects of Post-Operative Physiotherapy at MS Thera Cure Clinic. Our dedicated team is committed to providing personalized and effective rehabilitation, supporting you in your post-operative recovery journey for a faster and smoother return to optimal health.",
    ],
  },
  {
    title: "PNF (Proprioceptive Neuromuscular Facilitation)",
    link: "PNF",
    description:
      "Proprioceptive Neuromuscular Facilitation (PNF) is an innovative approach grounded in the principles of functional human anatomy and neurophysiology. At MS Thera Cure Clinic, we utilize PNF as an effective method to enhance movement patterns and promote overall functional well-being.",
    therapeuticBenefits: [
      {
        name: "Facilitation of Movement or Posture",
        description:
          "PNF techniques are designed to facilitate patients in achieving specific movements or postures, promoting functional and purposeful actions",
      },
      {
        name: "Increasing Flexibility, Strength, Coordination, and Functional Mobility",
        description:
          "PNF is a comprehensive approach that contributes to the improvement of various aspects of physical health, including:",
        subDescription: [
          {
            benefit: "Flexibility",
            description:
              "Enhance your range of motion through targeted PNF techniques.",
          },
          {
            benefit: "Strength",
            description:
              "PNF exercises are structured to strengthen muscles, promoting stability and power.",
          },
          {
            benefit: "Coordination",
            description:
              "Improve motor skills and coordination through carefully designed PNF interventions.",
          },
          {
            benefit: "Functional Mobility",
            description:
              "Experience enhanced functional mobility, ensuring the ability to perform everyday activities with ease.",
          },
        ],
      },
    ],
    endDes: [
      "Discover the transformative effects of PNF at MS Thera Cure Clinic. Our skilled practitioners are dedicated to providing personalized and effective PNF sessions, aiming to optimize your movement patterns and elevate your overall physical well-being",
    ],
  },
  {
    title: "Motor Relearning Programme",
    link: "Motor-Relearning-Programme",
    description:
      "The Motor Relearning Programme (MRP) is a specialized task-oriented approach employed at MS Thera Cure Clinic to enhance motor control. This program places emphasis on the re-learning of daily activities, promoting a comprehensive and targeted approach to motor skill improvement",
    therapeuticBenefits: [
      {
        name: "Effective Improvement in Functional Activities",
        description: "",
        subDescription: [
          {
            description:
              "The Motor Relearning Programme has proven effectiveness in enhancing functional mobility, empowering individuals to perform daily activities with increased ease and efficiency",
          },
        ],
      },
      {
        name: "Framed for Stroke Patients through a Task-Oriented Approach",
        description: "",
        subDescription: [
          {
            description:
              "MRP is specifically designed for stroke patients, utilizing a task-oriented approach that focuses on re-learning and improving motor skills compromised by the stroke eventy",
          },
        ],
      },
    ],
    endDes: [
      "Discover the transformative effects of the Motor Relearning Programme at MS Thera Cure Clinic. Our dedicated team is committed to providing personalized and effective sessions, tailoring the program to meet the unique needs of each individual for an enhanced quality of life and improved motor control",
    ],
  },
];
