/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import '../styles/footer.css'
import Logo from '../images/Logo.jpg'
import HomeIcon from '@mui/icons-material/Home';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Services } from './Services/ServicesInfo';
import { useNavigate } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer() {
  const navigate = useNavigate();
  return (
    <div className='footer'>
      <div className='footer-top'>
        <div className='text-white'>
          <img
            src={Logo}
            alt="logo"
            style={{ maxWidth: "200px", marginLeft: "-30px" }}
          >
          </img><br />
          Feel the relief,<br />
          Get healed,<br />
          The hands that care
        </div>
        <ul className="list-unstyled">
          <li><a href="#!">Services</a></li><br />
          {Services.map((service, i) => {
            return <li key={i}><a
              href={`/services/${service.link}`}
              onClick={(e) => {
                e.preventDefault()
                navigate(`/services/${service.link}`);

              }}>{service.name}</a></li>

          })}
        </ul>
        <ul className="list-unstyled">
          <li><a href="#!">CONTACT</a></li><br />
          <li><a href="https://maps.app.goo.gl/KgRrQr24ZKFmMytt5" style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "10px" }} target='_blank'><HomeIcon /> <div style={{ maxWidth: "350px", marginLeft: "10px" }}>361/A, Basudevpur Road, Ground Floor,<br /> 'Nilanjana' Apartment, Shyamnagar,<br /> West Bengal, 743127, India</div></a></li>
          <li><a href="mailto:contacts@mstheracure.com" style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}><EmailIcon sx={{ marginRight: "10px" }} />contacts@mstheracure.com  </a></li>
          <li><a href="tel:03335647255" style={{ display: "flex", alignItems: "flex-start", }}><WifiCalling3Icon sx={{ marginRight: "10px" }} /> (033) 3564 7255 </a></li>
        </ul>
        <ul className="list-unstyled">
          <li><a href="#!">Social Media</a></li><br />
          <li>  <a style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} href="https://www.facebook.com/theracureclinic/" target="_blank"><FacebookIcon sx={{ color: "white", marginRight: '5px' }} />Facebook </a></li>
          <li> <a style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} href=" https://www.instagram.com/thera.cure/" target="_blank"><InstagramIcon sx={{ color: "white", marginRight: '5px' }} />Instagram </a></li>
          <li> <a style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} href="https://www.youtube.com/@TheraCureClinic" target="_blank" rel="noreferrer"><YouTubeIcon sx={{ color: "white", marginRight: '5px' }} />YouTube </a></li>
          <li> <a style={{ display: "flex", alignItems: "center" }} href="https://twitter.com/theracureclinic" target="_blank" rel="noreferrer"><TwitterIcon sx={{ color: "white", marginRight: '5px' }} />Twitter </a></li>
        </ul>
      </div>
      <div className='text-white footer-bottom'>
        © 2023 Copyright: MS THERA-CURE CLINIC
      </div>
    </div>
  )
}

export default Footer