import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import Navbar from "../components/Navbar";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

function Register() {
  const [file, setFile] = useState("");
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: "",
    confpassword: "",
  });
  const [otp, setOtp] = useState(false)
  const [OTP, setOTP] = useState(false)

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;


  const inputChange = (e) => {
    const { name, value } = e.target;
    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  // const onUpload = async (e) => {
  //   const base64 = await convertToBase64(e.target.files[0]);
  //   setFile(base64);
  // };

  const formSubmit = async (e) => {
    let toastId;
    try {
      e.preventDefault();
      const { firstname, lastname, email, password, confpassword, mobile } =
        formDetails;
      if (!firstname || !email || !password || !confpassword || !mobile) {
        return toast.error("Input field should not be empty");
      } else if (firstname.length < 3) {
        return toast.error("First name must be at least 3 characters long");
      } else if (password.length < 5) {
        return toast.error("Password must be at least 5 characters long");
      } else if (password !== confpassword) {
        return toast.error("Passwords do not match");
      } else if (mobile.length < 10) {
        return toast.error("Mobile can't be less than 10 digit");
      }
      const pic =
        file === ""
          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
          : file;
      toastId = toast.loading('Registering...');
      const { data } = await axios.post(`${baseUrl}/user/register`, {
        firstname,
        lastname,
        mobile,
        email,
        password,
        pic,
      })
      console.log('data',data)
      if (data && data.msg) {
        toast.success(data.msg);
        setOtp(true);
      } else {
        toast.error("Something went wrong!!");
      }

    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        console.log('data', data);
        if (data) {
          toast.error(data);
        } else {
          toast.error("An error occurred"); // Default error message
        }
      } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received from server");
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error:", error.message);
        toast.error("Request failed");
      }
    } finally {
      toast.dismiss(toastId);
    }
  };


  const handleVerifyOTP = async (e) => {
    try {
      e.preventDefault();
      const { data } = await toast.promise(
        axios.post(`${baseUrl}/user/verifyotp`, {
          email: formDetails.email,
          otp: OTP
        }),
        {
          pending: "Verifying user...",
          success: "User verified",
          error: "Unable to verify user",
          loading: "Verifying user...",
        }
      );
      return navigate("/login");
    } catch (err) {

    }

  }

  return (
    <>
      <Navbar />
      <section className="register-section flex-center login">
        {!otp ? <div className="register-container flex-center">
          <h2 className="form-heading">Sign Up</h2>
          <form
            onSubmit={formSubmit}
            className="register-form"
          >
            <input
              type="text"
              name="firstname"
              className="form-input"
              placeholder="Enter your first name"
              value={formDetails.firstname}
              onChange={inputChange}
            />
            <input
              type="text"
              name="lastname"
              className="form-input"
              placeholder="Enter your last name"
              value={formDetails.lastname}
              onChange={inputChange}
            />
            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={formDetails.email}
              onChange={inputChange}
            />
            <input
              type="number"
              name="mobile"
              className="form-input"
              placeholder="Enter your phone no."
              value={formDetails.mobile}
              onChange={inputChange}
            />
            {/* <input
              type="file"
              onChange={onUpload}
              name="profile-pic"
              id="profile-pic"
              className="form-input"
            /> */}
            <input
              type="password"
              name="password"
              className="form-input"
              placeholder="Enter your password"
              value={formDetails.password}
              onChange={inputChange}
            />
            <input
              type="password"
              name="confpassword"
              className="form-input"
              placeholder="Confirm your password"
              value={formDetails.confpassword}
              onChange={inputChange}
            />
            <button
              type="submit"
              className="btn form-btn"
            >
              sign up
            </button>
          </form>
          <p>
            Already a user?{" "}
            <NavLink
              className="login-link"
              to={"/login"}
            >
              Log in
            </NavLink>
          </p>
        </div>
          : <div className="register-container flex-center">
            <h2 className="form-heading">OTP</h2>
            <form
              onSubmit={handleVerifyOTP}
              className="register-form"
            >
              <input
                type="number"
                name="firstname"
                className="form-input"
                placeholder="Enter OTP"
                onChange={e => setOTP(e.target.value)}
              />
              <button
                type="submit"
                className="btn form-btn"
              >
                Verify
              </button>
            </form>
          </div>
        }
      </section>
    </>
  );
}

export default Register;
