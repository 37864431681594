import React, { useState, useEffect } from "react";
import "../styles/user.css";
import axios from "axios";
import toast from "react-hot-toast";
import Loading from "./Loading";
import { setLoading } from "../redux/reducers/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import Empty from "./Empty";
import fetchData from "../helper/apiCall";
import '../styles/search.css'

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteremail, setFilterEmail] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.root);
  const baseUrl = process.env.REACT_APP_BASE_URL
  const getAllUsers = async (e) => {
    try {
      const temp = await fetchData(`${baseUrl}/user/getallusers`);
      setUsers(temp);
    } catch (error) { }
  };

  const deleteUser = async (userId) => {
    try {
      const confirm = window.confirm("Are you sure you want to delete?");
      if (confirm) {
        const { data } = await toast.promise(
          axios.delete(`${baseUrl}/user/deleteuser`, {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: { userId },
          }),
          {
            pending: "Deleting in...",
            success: "User deleted successfully",
            error: "Unable to delete user",
            loading: "Deleting user...",
          }
        );
        getAllUsers();
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getAllUsers();
    dispatch(setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="" style={{ flex: 6 }}>
          <h3 className="home-sub-heading" style={{ marginTop: "20px" }}>All Users</h3>
          <div className="search">
            <input
              type="text"
              placeholder="Search By Email"
              className="form-input"
              onChange={(e) => setFilterEmail(e.target.value)}
              value={filteremail}
            />
          </div>
          {users.length > 0 ? (
            <div className="user-container" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Pic</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Mobile No.</th>
                      <th>Age</th>
                      <th>Gender</th>
                      <th>Is Doctor</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((ele, i) => {
                      if (ele.email.includes(filteremail) || filteremail === "") {
                        return (
                          <tr key={ele._id}>
                            <td>{i + 1}</td>
                            <td>
                              <img
                                className="user-table-pic"
                                src={ele.pic}
                                alt={ele.firstname}
                              />
                            </td>
                            <td>{ele.firstname}</td>
                            <td>{ele.lastname}</td>
                            <td>{ele.email}</td>
                            <td>{ele.mobile}</td>
                            <td>{ele.age}</td>
                            <td>{ele.gender}</td>
                            <td>{ele.isDoctor ? "Yes" : "No"}</td>
                            <td className="select">
                              <button
                                className="btn user-btn"
                                onClick={() => {
                                  deleteUser(ele._id);
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        )
                      } else {
                        return <></>
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </section>
      )}
    </>
  );
};

export default Users;
