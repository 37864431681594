import React from 'react'
import './Gallery.css'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { gallery } from './GalleryInfo';
import { useNavigate } from "react-router-dom";

function Gallery() {
    const navigate = useNavigate();

    return (
        <section id="gallery">
            <Navbar />
            <div className='gallery-header'>
                <h3>Gallery</h3>
            </div>
            <div className='gallery-folder'>
                {gallery.map((g,i) => {
                    return <div key={i} className='gallery-card' onClick={() => { navigate(`/gallery/details?name=${g.name}`) }}>
                        <img src={g.labelImg} alt={g.name}></img>
                        <p>{g.name}</p>
                    </div>
                })}
            </div>
            <Footer />
        </section>
    )
}

export default Gallery;