import React, { useState } from 'react'
import '../styles/whyus.css'
import whyus1 from '../images/whyus1.jpg'
import whyus2 from '../images/whyus2.jpg'
import whyus3 from '../images/whyus3.jpg'
import whyus4 from '../images/whyus4.jpg'
import whyus5 from '../images/whyus5.jpg'
import whyus6 from '../images/whyus6.jpg'
import whyus7 from '../images/whyus7.jpg'
import whyus8 from '../images/whyus8.jpg'
import whyus21 from '../images/whyus21.jpg'
import whyus31 from '../images/whyus31.jpg'
import whyus41 from '../images/whyus41.jpg'
import whyus51 from '../images/whyus51.jpg'
import whyus61 from '../images/whyus61.jpg'
import whyus71 from '../images/whyus71.jpg'
import whyus81 from '../images/whyus81.jpg'

function WhyUs() {
    const [hoverIndex, setHoverIndex] = useState(0)
    return (
        <section id="whyus" className='whyus'>
            <div className='whyusHeader'>
                <div className='whyusheaderimg'><img src={whyus1} alt="whyus1"></img> </div>
                <div className='whyusheadername'>Why Us</div>
            </div>
            <div className='whyuscontent1'>
                <div className='whyussingle1' onMouseEnter={() => setHoverIndex(2)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 2 ? whyus21 : whyus2} alt="whyus2" height={60} width={60}></img> </div>
                    <div className=''>PAINLESS DIAGNOSIS</div>
                </div>
                <div className='whyussingle2' onMouseEnter={() => setHoverIndex(3)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 3 ? whyus31 : whyus3} alt="whyus3" height={80} width={60}></img> </div>
                    <div className=''>ENHANCING THE QUALITY OF LIFE THROUGH MANUAL THERAPY AND ADVANCED PHYSIOTHERAPEUTIC TREATMENT</div>
                </div>
                <div className='whyussingle3' onMouseEnter={() => setHoverIndex(4)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 4 ? whyus41 : whyus4} alt="whyus4" height={60} width={80}></img> </div>
                    <div className=''> NON-SURGICAL TREATMENT </div>
                </div>
            </div>
            <div className='whyuscontent2'>
                <div className='whyussingle1' onMouseEnter={() => setHoverIndex(8)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 8 ? whyus81 : whyus8} alt="whyus8" height={60} width={60}></img> </div>
                    <div className=''>WE ALSO HAVE HOME CARE FACILITIES</div>
                </div>

                <div className='whyussingle2' onMouseEnter={() => setHoverIndex(6)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright' ><img src={hoverIndex === 6 ? whyus61 : whyus6} alt="whyus6" height={60} width={60}></img> </div>
                    <div className=''>WE HAVE QUALIFIED PHYSIOTHERAPISTS WITH REMARKABLE ACADEMIC EXCELLENCE</div>
                </div>
                <div className='whyussingle3' onMouseEnter={() => setHoverIndex(7)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 7 ? whyus71 : whyus7} alt="whyus7" height={60} width={60}></img> </div>
                    <div className=''>WE ALSO PROVIDE ONLINE CONSULTATION</div>
                </div>
            </div>
            <div className='whyuscontent3'>
                <div className='whyussingle4' onMouseEnter={() => setHoverIndex(5)} onMouseLeave={() => setHoverIndex(0)}>
                    <div className='whyusmarginright'><img src={hoverIndex === 5 ? whyus51 : whyus5} alt="whyus5" height={60} width={60}></img> </div>
                    <div className=''> WE HAVE MULTISPECIALISTS TEAM FROM VARIOUS BRANCHES SUCH AS MSK/ORTHO , NEUROLOGY , CARDIOPULOMARY , PAEDIATRICS AND GERIATRICS</div>
                </div >

            </div>
        </section>
    )
}

export default WhyUs