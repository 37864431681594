import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/notification.css";
import Empty from "../components/Empty";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import fetchData from "../helper/apiCall";
import { setLoading } from "../redux/reducers/rootSlice";
import Loading from "../components/Loading";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.root);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getAllNotif = async (e) => {
    try {
      const temp = await fetchData(`${baseUrl}/notification/getallnotifs`);
      setNotifications(temp.reverse());
    } catch (error) {
      console.log("Error in getAllNotif",error)
     }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getAllNotif();
    dispatch(setLoading(false));
  }, []);

  const getTime = (time) => {
    const utcTimestamp = new Date(time);
    const istTimestamp = new Date(utcTimestamp.getTime() + (5.5 * 60 * 60 * 1000));
    return istTimestamp.toISOString()
  }

  return (
    <>
      <Navbar />
      {loading ? (
        <Loading />
      ) : (
        <section className="container notif-section">
          <h2 className="page-heading">Your Notifications</h2>

          {notifications.length > 0 ? (
            <div className="notifications">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Content</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications?.map((ele, i) => {
                    return (
                      <tr key={ele._id}>
                        <td>{i + 1}</td>
                        <td>{ele.content}</td>
                        <td>{getTime(ele.updatedAt).split("T")[0]}</td>
                        <td>{getTime(ele.updatedAt).split("T")[1].split(".")[0]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Empty />
          )}
        </section>
      )}
      <Footer />
    </>
  );
};

export default Notifications;
