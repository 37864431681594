import React from "react";
import {
  FaList, FaUser, FaSuitcase, FaUsers, FaEnvelope
} from "react-icons/fa";
import "../styles/sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { AiOutlineNotification } from "react-icons/ai";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sidebar = [
    {
      name: "Users",
      path: "/dashboard/users",
      icon: <FaUsers />,
    },

    {
      name: "Appointments",
      path: "/dashboard/appointments",
      icon: <FaList />,
    },
    {
      name: "Message",
      path: "/dashboard/message",
      icon: <FaEnvelope />,
    },
    {
      name: "Jobs",
      path: "/dashboard/careers",
      icon: <FaSuitcase />,
    },
    {
      name: "Job Data",
      path: "/dashboard/careers-data",
      icon: <FaSuitcase />,
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <FaUser />,
    },
    {
      name: "Pop Up",
      path: "/dashboard/pop-up",
      icon: <AiOutlineNotification />,
    },
  ];

  const logoutFunc = () => {
    dispatch(setUserInfo({}));
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <section className="sidebar-section flex-center">
      <div className="sidebar-container">
        <ul style={{ marginTop: "80px" }}>
          {sidebar.map((ele, i) => {
            return (
              <li key={i}>
                {ele.icon}
                <NavLink to={ele.path}>{ele.name}</NavLink>
              </li>
            );
          })}
        </ul>
        <div className="logout-container">
          <MdLogout />
          <p onClick={logoutFunc}>Logout</p>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
