import React from 'react';
import '../styles/appointment.css';
import { Link } from 'react-router-dom';
import appointment from '../images/appointment2.jpg'

const Appointment = () => {
    return (
        <section id="appointment" style={{ flex: '3', alignItems: 'flex-start', marginRight: '20px' }}>
            <div className="appointment">
                <div className='appointmentLeft'>
                    <div className='apppointmentHeading'>
                        <h1 className="brand-color">APPOINTMENT</h1><br />
                    </div>
                    <img src={appointment} alt="appointment" />
                </div>
                <div className='appointmentRight'>
                    <h1 className="">Make An AppointMent </h1><br />
                    <p className='content-aim'> <strong>OUR AIM -</strong> MAKING PHYSIOTHERAPY ACCESSIBLE TO EACH AND EVERYONE IN OUR SOCIETY.<br /><br />
                    </p>
                    <Link to="/appointment" className="btn btn-primary">Learn More</Link>
                </div>
            </div>
        </section>
    );
};

export default Appointment;