import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Loading() {
  return (
    <div style={{ minHeight: "100vh" }}>
      <BeatLoader className="flex-center loader" color="#00abe4" size={18} />
    </div>
  );
}

export default Loading;
