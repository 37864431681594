import React, { useEffect, useState } from 'react'
import Loading from './Loading';
import Empty from './Empty';
import fetchData from "../helper/apiCall";


function Message() {
    const [messages, setMessages] = useState([]);
    const [filtermessages, setFilterMessages] = useState([]);
    const [filterDate, setfilterDate] = useState("");
    const [loading, setLoading] = useState(false)
    const baseUrl = process.env.REACT_APP_BASE_URL

    const getAllMessage = async (e) => {
        try {
            const temp = await fetchData(`${baseUrl}/message/getallmessage`);
            setMessages(temp);
            setFilterMessages(temp);
        } catch (error) { }
    };

    useEffect(() => {
        setLoading(true)
        getAllMessage();
        setLoading(false)
    }, [])
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <section className="" style={{ flex: 6 }}>
                    <h3 className="page-heading" style={{ marginTop: "20px" }}>All Messages</h3>
                    <div className="search">
                        <div >
                            <p>Search By Date</p>
                            <input
                                type="date"
                                placeholder="Search By Date"
                                className="form-input"
                                onChange={(e) => setfilterDate(e.target.value)}
                                value={filterDate}
                            />
                        </div>
                        <button
                            className="btn user-btn"
                            style={{ marginLeft: "10px", marginRight: "20px", marginBottom: "10px" }}
                            onClick={() => setfilterDate("")}
                        >
                            Clear
                        </button>
                    </div>
                    {filtermessages.length > 0 ? (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Date</th>
                                            <th>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {messages?.map((ele, i) => {
                                            if (filterDate === "" || filterDate === ele.updatedAt.split("T")[0]) {
                                                return <tr key={ele._id}>
                                                    <td>{i + 1}</td>
                                                    <td>{ele.name}</td>
                                                    <td>{ele.email}</td>
                                                    <td>{ele.updatedAt.split("T")[0]}</td>
                                                    <td>{ele.message}</td>
                                                </tr>
                                            }
                                            else {
                                                return <></>
                                            }

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <Empty />
                    )}
                </section>
            )}
        </>
    );

}

export default Message