import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Empty from "./Empty";
import fetchData from "../helper/apiCall";
import '../styles/search.css'

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

const AdminAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [filterappointments, setFilterAppointments] = useState([]);
  const [filterDate, setfilterDate] = useState("");

  const baseUrl = process.env.REACT_APP_BASE_URL
  const getAllAppoint = async (e) => {
    try {
      const temp = await fetchData(`${baseUrl}/appointment/getallappointments`);
      setAppointments(temp);
      setFilterAppointments(temp);
    } catch (error) { }
  };

  const deleteUser = async (userId) => {
    try {
      const confirm = window.confirm("Are you sure you want to delete?");
      if (confirm) {
        const { data } = await toast.promise(
          axios.delete(`${baseUrl}/user/deleteuser`, {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: { userId },
          }),
          {
            pending: "Deleting in...",
            success: "User deleted successfully",
            error: "Unable to delete user",
            loading: "Deleting user...",
          }
        );
        getAllAppoint();
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    // dispatch(setLoading(true));
    getAllAppoint();
    // dispatch(setLoading(false));
  }, []);

  const handleAcceptReject = async (ele, status) => {
    try {
      const { data } = await toast.promise(
        axios.put(
          `${baseUrl}/appointment/accept_reject`,
          {
            appointid: ele._id,
            status: status
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ),
        {
          success: `Appointment ${status}`,
          error: `Unable to ${status} appointment`,
          loading: `${status} appointment...`,
        }
      );

      getAllAppoint();

    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <section className="" style={{ flex: 6 }}>
        <h3 className="page-heading" style={{ marginTop: "20px" }}>All Appointments</h3>
        <div className="search">
          <div >
            <p>Search By Date</p>
            <input
              type="date"
              placeholder="Search By Date"
              className="form-input"
              onChange={(e) => setfilterDate(e.target.value)}
              value={filterDate}
            />
          </div>
          <button
            className="btn user-btn"
            style={{ marginLeft: "10px", marginRight: "20px", marginBottom: "10px" }}
            onClick={() => setfilterDate("")}
          >
            Clear
          </button>
        </div>
        {filterappointments.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {/* <th>Doctor</th> */}
                    <th>Patient</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Booking Date</th>
                    <th>Booking Time</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments?.map((ele, i) => {
                    if (filterDate === "" || filterDate === ele.appointmentdate) {
                      return (
                        <tr key={ele._id}>
                          <td>{i + 1}</td>
                          {/* <td>
                          {ele.doctorId.firstname + " " + ele.doctorId.lastname}
                        </td> */}
                          <td>
                            {ele.patient}
                          </td>
                          <td>{ele.appointmentdate}</td>
                          <td>{ele.appointmenttime}</td>
                          <td>{ele.bookingdate}</td>
                          <td>{ele.updatedAt.split("T")[1].split(".")[0]}</td>
                          <td>{ele.status}</td>
                          <td>
                            <button
                              className={`btn user-btn accept-btn ${ele.status === "Accept" ? "disable-btn" : ""
                                }`}
                              disabled={ele.status === "Accept"}
                              onClick={() => handleAcceptReject(ele, "Accept")}
                              style={{ marginRight: "10px" }}
                            >
                              Accept
                            </button>
                            <button
                              className={`btn user-btn ${ele.status === "Decline" ? "disable-btn" : ""
                                }`}
                              disabled={ele.status === "Decline"}
                              onClick={() => handleAcceptReject(ele, "Decline")}
                            >
                              Decline
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      return <></>
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </section>
    </>
  );
};

export default AdminAppointments;
