export const Services = [
  {
    name: "Wax Bath",
    link: "Wax-Bath",
    desc: [
      "It is an application of molten paraffin wax on the affected distal extremities",
      "It is a form of moist heat.",
    ],
    benifit: [
      "It acts as an analgesic",
      "It has a circulatory effect",
      "It also has a stretching effect",
    ],
    img: require("../../images/Services/waxbath.jpg"),
  },
  {
    name: "Long Wave Therapy",
    link: "Long-Wave-Therapy",
    desc: [
      "It is the therapeutic application of deep heat in the targeted muscle",
    ],
    benifit: [
      "It increases metabolic functions",
      "It increases the blood supply",
      "It acts as a muscle relaxant",
      "It improves the range of motion",
      "It also acts as an analgesic",
    ],
    img: require("../../images/Services/Longwave.jpg"),
    info: [
      "Dr. Mainak’s theracure’s (MSTheracure) commitment to innovation and patient-centered care establishes us as the leading long wave therapy in his clinic. Our introduction of Longwave Diathermy equipment represents a pinnacle in therapeutic technology, offering unparalleled relief and joy to recovering patients what gives us an edge over others is our unwavering dedication to holistic healing and the profound impact of optimum temperature on metabolic issues and tissue ailments. Unlike conventional heat therapies like steam baths or warm sponging, our Longwave Diathermy meticulously targets and penetrates deeper into tissues, stimulating them with a therapeutic warmth that promotes accelerated recovery. Our methodology, rooted in ancient therapeutic principles, leverages the power of long wave therapy to address chronic pain and discomfort, achieving remarkable results where other treatments fall short.",
      "People who are embroiled and experiencing turbulence owing to long-standing pain can find relief while enjoying transformative experience that not only reinvigorate the body but also rejuvenate the spirit. Here, you can get to experience ancient wisdom with modern advancements, ensuring that each patient receives personalized care tailored to their unique needs. Experience the difference with MS Theracure - where compassion, expertise, and cutting-edge technology converge to redefine excellence in long wave therapy.",
    ],
  },
  {
    name: "Mobilisations",
    link: "Mobilisations",
    desc: [
      "The term ‘Mobilization’ means skilled passive movement of the articular surfaces which is performed by a physical therapist to improve joint mobility and thus decrease pain",
    ],
    benifit: [
      "Mobilization normalizes the joint function",
      "Reduces pain",
      "Improves range of motion",
      "Increases joint nutrition",
      "Improves muscle spasms",
    ],
    img: require("../../images/Services/Mobilization.jpg"),
  },
  {
    name: "Ultrasound Therapy",
    link: "Ultrasound-Therapy",
    desc: [
      "Therapeutic ultrasound in physical therapy uses the property of compression and rarefaction of sound waves with a frequency of 0.7 to 3.3 MHz.",
    ],
    benifit: [
      "Increases Tissue Perfusion (Oxygenation)",
      "Increases blood flow",
      "It helps in inflammatory conditions.",
      "It enhances tissue elasticity",
      "It reduces pain",
    ],
    img: require("../../images/Services/ultrasound.png"),
  },
  {
    name: "Interferential Therapy (IFT)",
    link: "Interferential-Therapy",
    desc: [
      "It is an electrotherapy modality that utilizes the therapeutic aspects of low-frequency currents without causing discomfort",
    ],
    benifit: [
      "It is beneficial in muscle stimulation",
      "It increases local blood flow",
      "It can be used under the following conditions-",
      "Stump pain (Post amputation)",
      "Vascular Insufficiency",
      "Reflex Sympathetic Dystrophy",
      "Myofascial Pain Syndrome",
    ],
    img: require("../../images/Services/IFT.jpg"),
  },
  {
    name: "TENS (Transcutaneous Electrical Nerve Stimulation)",
    link: "TENS",
    desc: [
      "TENS is a low-frequency electrical current that is used to stimulate peripheral nerves using skin surface electrodes aiming to relieve pain (acute or chronic).",
      "TENS is a non-invasive procedure.",
    ],
    benifit: [
      "It reduces pain",
      "It is beneficial in radiculopathies such as-",
      "Carpal Tunnel Syndrome",
      "Piriformis Syndrome (Sciatica)",
      "It is beneficial in post-operative pain",
    ],
    img: require("../../images/Services/TENS.jpg"),
  },
  {
    name: "EMS (Electronic Muscle Stimulation)",
    link: "EMS-Therapy",
    desc: [
      "Electrical muscle stimulation is used to target a motor nerve and cause a muscle response.",
    ],
    benifit: [
      "Muscle re-education",
      "Improves muscle function and muscle tone",
      "Helps in wound healing",
      "Increases range of motion",
      "Prevents atrophy of muscles",
    ],
    img: require("../../images/Services/EMS.png"),
  },
  {
    name: "LASER",
    link: "LASER",
    desc: [
      "LASER is one of the most recent therapeutic modalities available to a physiotherapist.",
      "LASER refers to the production of a beam of radiation that differs from ordinary light.",
    ],
    benifit: [
      "Analgesic effect",
      "Anti-inflammatory effect",
      "Improves blood circulation",
      "Promotes faster wound healing",
      "It helps in collagen production",
    ],
    img: require("../../images/Services/laser.png"),
  },
  {
    name: "Gait Training",
    link: "Gait-Training",
    desc: [
      "GAIT- A manner of walking or moving on foot or the pattern you walk",
      "WHY DOES ONE NEED GAIT TRAINING?<br/> Gait training is necessary to perform when the patient is walking improperly.",
      "HOW DOES A GAIT TRAINING WORK?<br/>Gait training involves practicing sets of exercises (task-oriented) that strengthen the muscles and increase the range of motion.",
    ],
    benifit: [
      "Gait Normalization",
      "Improves Walking speed",
      "Improves Balance",
      "Improves Coordination",
    ],
    img: require("../../images/Services/Gait-Training.jpg"),
  },
  {
    name: "Balance Training",
    link: "Balance-Training",
    desc: [
      "‘BALANCE’- The ability to maintain the line of gravity (LOG) within their base of support (BOS).",
    ],
    benifit: [
      "It helps with sensory problems.",
      "Improves attention.",
      "It gives visual biofeedback.",
      "It helps in strategy selection problems.",
    ],
    img: require("../../images/Services/Balance-Training.png"),
  },
  {
    name: "Dry Needling Therapy",
    link: "Dry-Needling-Therapy",
    desc: [
      "Dry needling therapy is an invasive procedure. In this therapy, a fine needle is inserted into the skin and muscle to release the myofascial trigger points.",
    ],
    benifit: [
      "Pain reduction by counter irritation and release of neurotransmitters.",
      "It promotes healing by fibroblastic activation.",
      "It increases the local blood supply.",
      "It improves muscle length and joint range of motion.",
      "It reduces spasticity by altering the resting length of the muscles",
    ],
    img: require("../../images/Services/DryNeedling.jpg"),
  },
  {
    name: "Manual Therapy",
    link: "Manual-Therapy",
    desc: [
      "Manual Therapy is a hands-on technique that is used to evaluate, treat and improve a patient’s status.",
    ],
    benifit: [
      "It stimulates biological activity such as-",
      "Moves the synovial fluid which eventually exchanges the nutrients to the avascular structures.",
      "It gives proprioceptive feedback.",
      "It reduces intraarticular or periarticular pressure",
    ],
    img: require("../../images/Services/ManualTherapy.jpg"),
  },
  {
    name: "Post-Operative Physiotherapy",
    link: "Post-Operative-Physiotherapy",
    desc: [
      "DO WE NEED POST-OPERATIVE PHYSIOTHERAPY?",
      "Yes. Physiotherapy has a vital role to play in the prevention of respiratory and circulatory complications",
    ],
    benifit: [
      "To prevent post-operative chest complications",
      "To prevent circulatory complications",
      "To maintain muscle power and joint range of motion",
      "To prevent pressure sore",
      "To improve and enhance bed mobility",
    ],
    img: require("../../images/Services/Post-Operative-Physiotherapy.png"),
  },
  {
    name: "PNF (Proprioceptive Neuromuscular Facilitation)",
    link: "PNF",
    desc: [
      "Proprioceptive neuromuscular facilitation is an approach that is based on the principles of functional human anatomy and neurophysiology",
    ],
    benifit: [
      "PNF facilitates the patient in achieving a movement or posture",
      "PNF helps in increasing flexibility, strength, coordination, and functional mobility",
    ],
    img: require("../../images/Services/PNF.png"),
  },
  {
    name: "Motor Relearning Programme",
    link: "Motor-Relearning-Programme",
    desc: [
      "Motor Relearning Programme is a task-oriented approach to improve motor control, focusing on the re-learning of daily activities",
    ],
    benifit: [
      "It is effective in improving functional mobility.",
      "MRP is framed for stroke patients through a task-oriented approach",
    ],
    img: require("../../images/Services/MRP.jpg"),
  },
];
