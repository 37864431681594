// src/Modal.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./Modal.css";
import axios from "axios";
Modal.setAppElement("#root"); // Set the root element for accessibility

const PopUp = ({ isOpen, onRequestClose }) => {
  const [PopImg, setPopImg] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      transition: "transform 0.3s ease-out",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      transition: "background-color 0.3s ease-out",
      zIndex: 99999,
    },
  };

  useEffect(() => {
    const fetchPopupImage = async () => {
      try {
        const response = await axios.get(`${baseUrl}/user/getPopupImage`);
        const image = response.data.images.reverse();
        if (image.length) {
          const imgUrl = baseUrl.replace("/api", "");
          const srcImg = `${imgUrl}/uploads/${image[0].filename}`;
          setPopImg(srcImg);
        }
      } catch (error) {
        console.error("Error fetching pop-up image:", error);
      }
    };
    fetchPopupImage();
  }, [baseUrl]);

  return (
    <>
      {PopImg ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          style={modalStyle}
          closeTimeoutMS={300}
        >
          <div>
            <img src={PopImg} alt="Modal" className="modal-image" />
            <button className="close-button" onClick={onRequestClose}>
              Close
            </button>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default PopUp;
