import "./styles/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import { Protected, Public, Admin } from "./middleware/route";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Doctors from "./pages/Doctors";
import Appointments from "./pages/Appointments";
import Error from "./pages/Error";
import Notifications from "./pages/Notifications";
import ApplyDoctor from "./pages/ApplyDoctor";
import Dashboard from "./pages/Dashboard";
import BookAppointment from "./pages/BookAppointment";
import AppointmentSteps from "./components/AppointmentSteps";
import Teams from "./components/Teams";
import ServiceDetails from "./components/Services/ServiceDetails";
import Gallery from "./components/Gallery/Gallery";
import GalleryDetail from "./components/Gallery/GalleryDetail";
import Careers from "./components/Career/Careers";
import CareerApply from "./components/Career/CareerApply";
import Tracking from "./components/Tracking";
import ForgetPassword from "./pages/ForgetPassword";
import Holiday from "./pages/Holiday";
import Service from "./components/Services/Services";
import Schema from "./components/Schema";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <Tracking />
      <Helmet>
        <title>Physiotherapy & Occupational Therapy | MS Thera-Cure Clinic Kolkata</title>
        <meta name="description" content="Get expert physiotherapy and occupational therapy at MS Thera-Cure Clinic in Shyamnagar, Kolkata. Personalized care for your recovery. Book now!"></meta>
      </Helmet>
      <Schema />
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={
            <Public>
              <Register />
            </Public>
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Home />} />
        <Route path="/about" element={<Home />} />
        <Route path="/holiday" element={<Holiday />} />
        <Route path="/doctors" element={<Doctors />} />
        <Route
          path="/appointments"
          element={
            <Protected>
              <Appointments />
            </Protected>
          }
        />
        <Route
          path="/book-appointments"
          element={
            <Protected>
              <BookAppointment />
            </Protected>
          }
        />
        <Route path="/appointment" element={<AppointmentSteps />} />
        <Route path="/our-team" element={<Teams />} />
        <Route path="/service" element={<Service />} />
        <Route path="/services/:servicesType" element={<ServiceDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/details" element={<GalleryDetail />} />
        <Route path="/careers" element={<Careers isAdmin={false} />} />
        <Route path="/careers/apply" element={<CareerApply />} />
        <Route path="/forget-password" element={<ForgetPassword />} />

        <Route
          path="/notifications"
          element={
            <Protected>
              <Notifications />
            </Protected>
          }
        />
        <Route
          path="/applyfordoctor"
          element={
            <Protected>
              <ApplyDoctor />
            </Protected>
          }
        />
        <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/dashboard/users"
          element={
            <Admin>
              <Dashboard type={"users"} />
            </Admin>
          }
        />
        <Route
          path="/dashboard/message"
          element={
            <Admin>
              <Dashboard type={"message"} />
            </Admin>
          }
        />
        <Route
          path="/dashboard/doctors"
          element={
            <Admin>
              <Dashboard type={"doctors"} />
            </Admin>
          }
        />
        <Route
          path="/dashboard/pop-up"
          element={
            <Admin>
              <Dashboard type={"pop-up"} />
            </Admin>
          }
        />
        <Route
          path="/dashboard/appointments"
          element={
            <Protected>
              <Dashboard type={"appointments"} />
            </Protected>
          }
        />
        <Route
          path="/dashboard/careers"
          element={
            <Protected>
              <Dashboard type={"careers"} />
            </Protected>
          }
        />
        <Route
          path="/dashboard/careers-data"
          element={
            <Protected>
              <Dashboard type={"careers-data"} />
            </Protected>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
