import React from "react";
import '../styles/aboutus.css'
import aboutus from '../images/aboutus1.JPG'

const AboutUs = () => {

  return (
    <>
      <section className="container" id="about">
        <h2 className="page-heading about-heading">About Us</h2>
        <div className="aboutus">
          <div className="aboutusdown">
            <div className="aboutuscontent">
              <p>Thera-Cure is the process of determining the priority of a patient’s treatments
                based on the severity of their condition. It’s a highly equipped clinic specialized
                in the treatment of Musculoskeletal and Neurological problems. We started our
                journey on 15th August 2019 with Dr. Mainak Sur (PT) at Feeder Road,
                Shyamnagar. Now this clinic is located at 361/A, Basudevpur Road, Ground
                floor- 'Nilanjana Apartment', Shyamnagar, North 24 Parganas.</p>
              <p >
                This clinic aims at providing advanced physiotherapeutic treatment with the
                recent evidence-based protocol. We have qualified Physiotherapists who are
                graduated and also have done the Masters from UGC Recognized Universities
                i.e., WBUHS, RGUHS, SVU, Brainware University, and other govt. & private
                universities etc. We aim at providing high-quality and result-oriented
                physiotherapeutic approaches with progress reports for our patients. We have a
                team of multi-specialty physiotherapists who have specializations in various
                subjects like orthopedics, Neurology, Cardiopulmonary, etc. We also provide
                online consultations and home care facilities.
              </p>
              <p>
                In a span of 5 years of our journey from Thera-Cure Advanced Physiotherapy
                Clinic, we are committed to all our patients with quality-assured result-oriented
                physiotherapeutic treatment
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
