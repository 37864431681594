import React from "react";
import FAQCard from "./FAQCard";
import { FAQData } from "./FAQData";

function FAQ({ name }) {
  return (
    <div
      style={{
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
      }}
    >
      {FAQData[name] && (
        <h4 style={{ textAlign: "center", margin: "20px" }}>
          Frequently Asked Questions (FAQ)
        </h4>
      )}
      {FAQData[name]?.map((faq) => {
        return <FAQCard question={faq.question} answer={faq.answer} />;
      })}
    </div>
  );
}

export default FAQ;
