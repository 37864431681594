import React from "react";

const List = ({ name, description, subList }) => {
  return (
    <div>
      <div
        style={{
          margin: "10px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <p style={{ marginRight: "5px" }}> • </p>
        <p>
          <strong>{name} </strong>
          {description}
        </p>
      </div>
      {subList?.map((s, index) => {
        return (
          <div
            style={{
              marginLeft: "10%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <p style={{ marginRight: "5px" }}> {index + 1}. </p>
            <p>
              <strong>{s.name} </strong>
              {s.description}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default List;
