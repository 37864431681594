import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Link,
    Menu,
    MenuItem,
    Box,
    IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import Logo from '../images/Logo.jpg'
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import '../styles/navbar.css';

function HoverLink({ href, children }) {
    const navigate = useNavigate();


    if (href.includes("#")) {
        return <Link
            href={href}
            className="link"
            sx={{ my: 2, mx: 0 }}
            variant="button"
        >
            <span className="linkText">{children}</span>
        </Link>
    }
    return (
        <Link
            className="link"
            sx={{ my: 2, mx: 0 }}
            variant="button"
            onClick={() => navigate(href)}
        >
            <span className="linkText">{children}</span>
        </Link>
    );
}

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const logoutFunc = () => {
        dispatch(setUserInfo({}));
        localStorage.removeItem("token");
        window.location.reload(true);
        navigate("/");
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleScroll = (event, target) => {
        event.preventDefault();
        const element = document.querySelector(target);
        window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className="miniHeader">
                <div style={{ maxWidth: "80%", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: '20px' }}>
                        <Link href="tel:03335647255"> <div style={{ display: "flex", alignItems: "center", color: "white" }}><WifiCalling3Icon /> (033) 3564 7255</div></Link>
                        <Link href="mailto:contacts@mstheracure.com"> <div style={{ display: "flex", alignItems: "center", color: "white", marginLeft: "20px" }}><EmailIcon /> contacts@mstheracure.com</div></Link>
                    </div>
                    <div>
                        <Link href="https://www.facebook.com/theracureclinic/" target="_blank"><FacebookIcon sx={{ color: "white" }} /> </Link>
                        <Link href=" https://www.instagram.com/thera.cure/" target="_blank"><InstagramIcon sx={{ color: "white", marginLeft: "10px" }} /> </Link>
                        <Link href="https://www.youtube.com/@TheraCureClinic" target="_blank"><YouTubeIcon sx={{ color: "white", marginLeft: "10px" }} /> </Link>
                        <Link href="https://twitter.com/theracureclinic" target="_blank"><TwitterIcon sx={{ color: "white", marginLeft: "10px" }} /> </Link>
                    </div>
                </div>
            </div>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, textAlign: 'center', justifyContent: "center", }}
            >
                <Toolbar sx={{ flexWrap: "wrap", justifyContent: 'center', textAlign: "center", display: { xs: "flex", md: "none" } }}>
                    <Typography
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, fontWeight: "bold" }}
                    >
                        <Link href="/">
                            <img
                                src={Logo}
                                alt="logo"
                                style={{ maxWidth: "200px" }}
                            ></img>
                        </Link>
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 2,
                            display: { xs: "flex", md: "none" },
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ fontSize: "3rem" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {token ? <><MenuItem onClick={handleCloseNavMenu}>
                                <HoverLink
                                    href="/appointments"
                                >
                                    Appointments
                                </HoverLink>
                            </MenuItem>
                                <MenuItem>
                                    <HoverLink

                                        color="text.primary"
                                        href="/book-appointments"


                                        underline="none"

                                    >
                                        Book Appointment
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <HoverLink
                                        href="/notifications"
                                    >
                                        Notification
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem>
                                    <HoverLink

                                        color="text.primary"
                                        href="/profile"


                                        underline="none"

                                    >
                                        Profile
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        variant="contained"

                                        size="large"
                                        onClick={() => {
                                            logoutFunc()
                                        }}
                                    >
                                        Logout
                                    </Button>
                                </MenuItem>
                            </> : <><MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, '#hero') }}>
                                <HoverLink
                                    href="/"
                                >
                                    Home
                                </HoverLink>
                            </MenuItem>
                                <MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, 'gallery') }}
                                >
                                    <HoverLink

                                        color="text.primary"
                                        href="/service"
                                        underline="none"
                                    >
                                        Services
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, 'gallery') }}
                                >
                                    <HoverLink

                                        color="text.primary"
                                        href="/gallery"
                                        underline="none"
                                    >
                                        Gallery
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, '#about') }}>
                                    <HoverLink

                                        color="text.primary"
                                        href="/about"
                                        underline="none"
                                    >
                                        About Us
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, '#team') }}>
                                    <HoverLink
                                        color="text.primary"
                                        href="/our-team"
                                        underline="none"
                                    >
                                        Our Team
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem onClick={(e) => { handleCloseNavMenu(); handleScroll(e, '#contact') }}>
                                    <HoverLink
                                        color="text.primary"
                                        href="/contact"
                                        underline="none"
                                    >
                                        Contact Us
                                    </HoverLink>

                                </MenuItem>
                                <MenuItem>
                                    <HoverLink
                                        color="text.primary"
                                        href="/holiday"
                                        underline="none"
                                    >
                                        Holidays
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem>
                                    <HoverLink
                                        color="text.primary"
                                        href="/careers"
                                        underline="none"
                                    >
                                        Careers
                                    </HoverLink>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        variant="contained"

                                        size="large"
                                        onClick={() => {
                                            navigate("/login");
                                        }}
                                    >
                                        Login
                                    </Button>
                                </MenuItem>
                            </>
                            }
                        </Menu>
                    </Box>
                </Toolbar>
                {token ? <><Toolbar sx={{ flexWrap: "wrap", justifyContent: 'center', textAlign: 'center', display: { xs: "none", md: "flex" } }}>

                    <Typography
                        variant="h5"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, fontWeight: "bold" }}
                    >
                        <Link href="/">
                            <img
                                src={Logo}
                                alt="logo"
                                style={{ maxWidth: "200px" }}
                            ></img>
                        </Link>
                    </Typography>
                    <nav>
                        <HoverLink
                            href="/appointments"



                        >
                            Appointments
                        </HoverLink>

                        <HoverLink
                            color="text.primary"
                            href="/book-appointments"
                            underline="none"
                        >
                            Book Appointment
                        </HoverLink>
                        <HoverLink
                            color="text.primary"
                            href="/notifications"
                            underline="none"
                        >
                            Notification
                        </HoverLink>
                        <HoverLink

                            color="text.primary"
                            href="/profile"


                            underline="none"

                        >
                            Profile
                        </HoverLink>

                    </nav>
                    <Button
                        variant="contained"

                        size="large"
                        onClick={() => {
                            logoutFunc()
                        }}
                    >
                        Logout
                    </Button>
                </Toolbar>
                </> :
                    <>
                        <Toolbar sx={{ justifyContent: 'center', textAlign: 'center', flexWrap: "wrap", display: { xs: "none", md: "flex" } }}>
                            <nav style={{ display: "flex", flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <Link href="/">
                                    <img
                                        src={Logo}
                                        alt="logo"
                                        style={{ maxWidth: "200px" }}
                                    ></img>
                                </Link>
                                <HoverLink
                                    href="/"

                                >
                                    Home
                                </HoverLink>
                                <HoverLink
                                    color="text.primary"
                                    href="/service"
                                    underline="none"

                                >
                                    Services
                                </HoverLink>
                                <HoverLink
                                    color="text.primary"
                                    href="/gallery"
                                    underline="none"

                                >
                                    Gallery
                                </HoverLink>
                                <HoverLink

                                    color="text.primary"
                                    href="/about"
                                    underline="none"

                                >
                                    About Us
                                </HoverLink>
                                <HoverLink

                                    color="text.primary"
                                    href="/our-team"
                                    underline="none"
                                >
                                    Our Team
                                </HoverLink>
                                <HoverLink

                                    color="text.primary"
                                    href="/contact"
                                    underline="none"

                                >
                                    Contact Us
                                </HoverLink>
                                <HoverLink
                                    color="text.primary"
                                    href="/holiday"
                                    underline="none"
                                >
                                    Holidays
                                </HoverLink>
                                <HoverLink
                                    color="text.primary"
                                    href="/careers"
                                    underline="none"
                                >
                                    Careers
                                </HoverLink>
                                <Button
                                    variant="contained"

                                    size="large"
                                    onClick={() => {
                                        navigate("/login");
                                    }}
                                >
                                    Login
                                </Button>
                            </nav>
                        </Toolbar>
                    </>
                }
            </AppBar>
        </>
    );
};

export default Navbar;
