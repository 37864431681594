export const festivals = [
      {
        "name": "Birth Day of Swami Vivekananda",
        "numberOfDays": 1,
        "dayOfWeek": "Friday",
        "englishDate": "12th January"
      },
      {
        "name": "Netaji's Birth Day",
        "numberOfDays": 1,
        "dayOfWeek": "Tuesday",
        "englishDate": "23rd January"
      },
      {
        "name": "Republic Day",
        "numberOfDays": 1,
        "dayOfWeek": "Friday",
        "englishDate": "26th January"
      },
      {
        "name": "Saraswati Puja [Sree Panchami]",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "14th February"
      },
      {
        "name": "Dol-jatra",
        "numberOfDays": 1,
        "dayOfWeek": "Monday",
        "englishDate": "25th March"
      },
      {
        "name": "Holi",
        "numberOfDays": 1,
        "dayOfWeek": "Tuesday",
        "englishDate": "26th March"
      },
      {
        "name": "Bengali New Year's Day (Nababarsha)",
        "numberOfDays": 1,
        "dayOfWeek": "Sunday",
        "englishDate": "14th April"
      },
      {
        "name": "Eid-Ul-Fitr",
        "numberOfDays": 1,
        "dayOfWeek": "Thursday",
        "englishDate": "11th April"
      },
      {
        "name": "May Day",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "1st May"
      },
      {
        "name": "Rathayatra",
        "numberOfDays": 1,
        "dayOfWeek": "Sunday",
        "englishDate": "7th July"
      },
      {
        "name": "Independence Day",
        "numberOfDays": 1,
        "dayOfWeek": "Thursday",
        "englishDate": "15th August"
      },
      {
        "name": "Janmashtami",
        "numberOfDays": 1,
        "dayOfWeek": "Monday",
        "englishDate": "26th August"
      },
      {
        "name": "Birth Day of Gandhiji & Mahalaya",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "2nd October"
      },
      {
        "name": "Additional Day for Durga Puja Maha Panchami",
        "numberOfDays": 1,
        "dayOfWeek": "Tuesday",
        "englishDate": "8th October"
      },
      {
        "name": "Additional Day for Durga Puja Maha Sasthi",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "9th October"
      },
      {
        "name": "Maha Saptami to Bijoya Dashami",
        "numberOfDays": 3,
        "dayOfWeek": "Saturday",
        "englishDate": "10th October to 12th October"
      },
      {
        "name": "Additional Day in connection with Durga Puja",
        "numberOfDays": 3,
        "dayOfWeek": "Sunday to Tuesday",
        "englishDate": "13th October to 15th October"
      },
      {
        "name": "Lakshmi Puja",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "16th October"
      },
      {
        "name": "Kali Puja/Diwali",
        "numberOfDays": 1,
        "dayOfWeek": "Thursday",
        "englishDate": "31st October"
      },
      {
        "name": "Additional Day in connection with Kali Puja/Diwali",
        "numberOfDays": 2,
        "dayOfWeek": "Friday and Saturday",
        "englishDate": "1st November & 2nd November"
      },
      {
        "name": "Bhratridwitiya",
        "numberOfDays": 1,
        "dayOfWeek": "Sunday",
        "englishDate": "3rd November"
      },
      {
        "name": "Christmas Day",
        "numberOfDays": 1,
        "dayOfWeek": "Wednesday",
        "englishDate": "25th December"
      }
    ]
  
  